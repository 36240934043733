.account-box {
    width: 50%;
    margin-left: 50px;
}

.account-edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0;
    a {
        text-decoration: none;
        color: #000000;
    }
}

.account-info-box {
    display: flex;
    padding: 10px 0;
}

.account-info-title {
    width: 150px;
}

@media (max-width: 768px) {
    .account-info-title {
        width: 100px;
    }
    .account-box {
        width: 100%;
        margin-left: 0px;
        padding: 20px;
    }
}
