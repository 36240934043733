.toolbar {
    position: relative;
    width: 100%;
    background: transparent;
    height: 50px;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 40px 0px;
    transition: 0.5s;
}

.toolbar__navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.toolbar__logo {
    // margin-left: 1rem;

    a {
        color: white;
        text-decoration: none;
        font-size: 1.5rem;
    }
}

.toolbar_new-link {
    width: 100%;
    display: flex;
}

.toolbar_navigation-lists {
    position: relative;
    flex: 1;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        font-size: 14px;
        li {
            // padding: 0 0.5rem;
            position: relative;
            margin-right: 2rem;

            .toolbar_nav_item_selected {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 58px;
                width: 80px;
                height: 5px;
                background: #61696d;
            }
        }

        a {
            color: #3e3d38;
            text-decoration: none;

            &:hover,
            &:active {
                color: $main_color;
            }
        }
    }
}

.toolbar_cart {
    color: $main_color;
    font-size: 1.5rem;
    cursor: pointer;
}

.toolbar_navigation-items {
    a {
        color: black;
        text-decoration: none;

        &:hover,
        &:active {
            color: orange;
        }
    }
}

.toolbar_person_area {
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
}

.toolbar_search_box {
    display: flex;
    align-items: center;
}

.toolbar_nav_item_yellow {
    color: $main_color;
    font-size: 16px;
}

.toolbar_nav_item_normal {
    font-size: 16px;
}

.toolbar.active {
    background: rgba(0, 0, 0, 0.6);
    padding: 20px 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    a {
        color: white;
    }
}

.toolbar_cart_area {
    width: 50px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
}

.toolbar_divider_full {
    display: block;
}

.toolbar_cart_item_count {
    position: absolute;
    right: 5px;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fddc3a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
}

@media (max-width: 768px) {
    .toolbar__navigation {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: -10px;
    }
    .toolbar_new-link {
        width: auto;
        display: flex;
    }
    .toolbar__new-header {
        width: 100%;
        display: flex;
    }
    .toolbar_navigation-items,
    .toolbar_navigation-lists {
        display: none;
    }

    .toolbar,
    .toolbar.active {
        margin-top: 50px;
        padding: 0;
    }
    .toolbar__logo {
        flex: 1;
        display: flex;
        justify-content: center;
    }
    .toolbar_divider_full {
        display: none;
    }

    .toolbar_cart_area {
        width: 50px;
        display: flex;
        justify-content: center;
        position: relative;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (min-width: 769px) {
    .toolbar__toggle-button {
        display: none;
    }

    .toolbar__logo {
        margin-left: 0;
    }
}
