.product_filter_container {
    position: relative;
    width: 100%;
    padding: 30px 10px;

    .product_filter_button_grop {
        position: relative;
        display: flex;
        .product_filter_button_item {
            .product_filter_button {
                position: relative;
                width: 100px;
                height: 40px;
                border: 1px solid #61696d;
                background: transparent;
                border-radius: 28px;
                margin-right: 30px;
                cursor: pointer;
            }
            .product_filter_button_selected {
                background: #fddc3a;
                border: none;
            }

            .product_filter_openitem,
            .product_filter_openitem_right {
                position: absolute;
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                width: 200px;
                border: 1px solid #ededed;
                border-radius: 7px;
                padding: 20px;
                font-size: 12px;
                background: white;
                z-index: 1;
                .product_filter_openitem_map {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .product_filter_container {
        width: 100%;
        padding: 30px 0;
        .product_filter_button_grop {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            .product_filter_button_item {
                .product_filter_button {
                    width: 80px;
                    height: 40px;
                    border-radius: 25px;
                    font-size: 12px;
                    margin-right: 0;
                }
                .product_filter_openitem_right {
                    position: absolute;
                    left: 150px;
                    margin-top: 10px;
                    display: flex;
                    width: 200px;
                }
            }
        }
    }
}
