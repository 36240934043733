.text-danger {
    color: red;
}

.form-control {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    outline: none;
}

.form-control-textarea {
    margin-top: 10px;
    width: 100%;
    height: 200px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    outline: none;
}

.input-box-nolabel {
    padding: 10px 10px;
}
.form-control-nolabel {
    width: 100%;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    outline: none;
    &::placeholder {
        font-size: 12px;
        color: #b2b2b2;
    }
}
.form-control-nolabel-select {
    width: 100%;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    outline: none;
    color: #b2b2b2;
    font-size: 14px;
}
.is-invalid {
    border: 1px solid red;
}

.inputRounded {
    border-radius: 10px;
}

.input-box {
    padding: 10px 20px;
}

.input-label {
    color: #707070;
    font-size: 14px;
}

.input-box-small {
    padding: 5px;
}

.form-control-small {
    margin-top: 5px;
    width: 100%;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    font-size: 14px;
    outline: none;
}

.card-element {
    padding: 20px 0px;
    color: #32325d;
    font-size: 18px;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
}

.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}
