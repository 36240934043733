@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital@1&family=Oswald:wght@400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arimo', sans-serif;
    // letter-spacing: 1px;
}

.divider-mobile-page {
    display: none;
}
@media (max-width: 768px) {
    .divider-mobile-page {
        display: block;
        padding-bottom: 20px;
    }
}

button {
    color: #000000;
    transition: all 0.3s ease-in-out;
}

a {
    text-decoration: none;
}
a:link {
    color: #000000;
}
a:hover {
    color: #edc400;
}

a:visited {
    color: #000000;
}

@import './globalVariable.scss';
@import './homepage.scss';
@import './loading.scss';
@import './toolbar.scss';
@import './sideDrawer.scss';
@import './backDrop.scss';
@import './footer.scss';
@import './main.scss';
@import './picturePoint.scss';
@import './products.scss';
@import './globalscss.scss';
@import './pageEndButton.scss';
@import './styleHeader.scss';
@import './kitchenPage.scss';
@import './vanitiesPage.scss';
@import './accessoriesPage.scss';
@import './plannerPage.scss';
@import './supportPage.scss';
@import './productsList.scss';
@import './productsListHeader.scss';
@import './productFilter.scss';
@import './navHeader.scss';
@import './product.scss';
@import './productDesc.scss';
@import './productPic.scss';
@import './recommenditem.scss';
@import './warranty.scss';
@import './cabinetCare.scss';
@import './selfAssemble.scss';
@import './faq.scss';
@import './aboutUs.scss';
@import './samples.scss';
@import './searchBar.scss';
@import './carts.scss';
@import './login.scss';
@import './input.scss';
@import './signin.scss';
@import './continue.scss';
@import './layout.scss';
@import './guestCheckout.scss';
@import './clientCheckout.scss';
@import './checkoutStage.scss';
@import './shippingOption.scss';
@import './order.scss';
@import './orderInfo.scss';
@import './paymentSuccess.scss';
@import './account.scss';
@import './productOption.scss';
@import './addToCartPopUp.scss';
@import './addToCarts.scss';
@import './displayMessage.scss';
@import './shoppingList.scss';
@import './requestDesign.scss';
@import './clientRequestDesign.scss';
@import './clientDesignInfo.scss';
@import './priceMatch.scss';
@import './bestPriceGrarantee.scss';
@import './commingsoon.scss';
@import './printCart.scss';
@import './boGo.scss';
@import './boxingPromo.scss';
@import './countertop.scss';
@import './paymentCharge.scss';
@import './paymentReceipt.scss';
