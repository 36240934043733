.search_bar_box {
    position: relative;
    top: -30px;
    width: 98%;
    min-height: 400px;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    z-index: 200;
    display: flex;
    justify-content: center;
    .search_bar_area {
        width: 90%;
        position: relative;
        display: inline-block;
        padding: 20px;
        .search_bar_text {
            padding: 10px;
            height: 20px;
            outline: none;
        }
    }

    .search_bar_result {
        position: absolute;
        top: 80px;
        left: 6%;
        width: 90%;
        height: 300px;
        overflow: scroll;
        .search_bar_list {
            display: flex;
            align-items: center;
            padding: 10px 0;
            .search_bar_list_img {
                margin-right: 20px;
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
            .search_bar_list_name {
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 768px) {
    .search_bar_box {
        position: relative;
        top: 30px;
        width: 98%;
        min-height: 300px;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;

        .search_bar_result {
            height: 200px;
        }
    }
}
