.client-checkout-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.client-checkout-info {
    width: 50%;
}

@media (max-width: 768px) {
    .client-checkout-box {
        padding: 0px 10px;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin-top: 20px;
    }

    .client-checkout-info {
        width: 100%;
    }
}
