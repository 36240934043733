.accessories_small_pic {
    position: relative;
    width: 100%;
    height: 70%;
    padding-bottom: 70%;
}

.accessories_small_bottom {
    width: 100%;
    height: 30%;
}

.accessories_small_title_box {
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.5);
    // border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;

    z-index: 2;
    // &:hover {
    //     box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    // }
    h2 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.3em;
        color: white;
    }
    p {
        color: white;
        margin-top: 14px;
    }
}

.accessories_big_pic {
    position: relative;
    width: 100%;
    height: 70%;
    padding-bottom: 50%;
}

.accessories_big_bottom {
    width: 100%;
    height: 30%;
}

.accessories_big_title_box {
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.5);
    // border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;

    z-index: 2;
    // &:hover {
    //     box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    // }
    h2 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5em;
        color: white;
    }
    p {
        color: white;
        margin-top: 1rem;
    }
}

@media (max-width: 768px) {
    .accessories_small_title_box {
        h2 {
            font-size: 1.5em;
        }
        p {
            margin-top: 12px;
        }
    }
    .accessories_big_pic {
        padding-bottom: 70%;
    }
    .accessories_big_title_box {
        h2 {
            font-size: 1.5em;
        }
        p {
            margin-top: 12px;
        }
    }
}
