.info-back-button {
    width: 148px;
    height: 33px;
    background: #fff5c4 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.info-delete-button {
    width: 148px;
    height: 33px;
    background: #fff0f5 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    &:hover {
        background: #ff9999 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.design_info_hr {
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: #e5e5e5;
}

.design_info_top_container {
    padding: 20px 0;
    display: flex;
}

.design_info_top_left {
    width: 50%;
}

.design_info_pic {
    width: 100%;
    height: 300px;
    object-fit: contain;
}
.design_info_top_right {
    width: 50%;
}

.design_info_room_details_container {
    width: 100%;
    display: flex;
    padding: 10px 0;
}

.design_info_room_details_title {
    width: 100px;
}

.design_info_pic_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.design_info_pic_item {
    width: 18%;
}

.request-design-kitchen-size-pic {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.design_info_pic_item_title {
    font-size: 10px;
}

.design_info_other_details_container {
    display: flex;
}

.design_info_other_details_item {
    width: 50%;
}

@media (max-width: 768px) {
    .design_info_top_container {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .design_info_top_left {
        width: 100%;
    }

    .design_info_top_right {
        width: 100%;
    }
    .design_info_button_box {
        padding: 10px;
    }

    .design_info_other_details_container {
        display: flex;
        flex-direction: column;
    }

    .design_info_other_details_item {
        width: 100%;
    }
}
