.request-design-title {
    text-align: center;
    padding: 20px 0;
    font-size: 24px;
}

.request-design-form-container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.request-design-form-buildtype-box {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
}

.request-design-form-buildtype-list {
    width: 200px;

    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    padding: 20px;
}

.request-design-form-buildtype-list.active {
    background: #fff5c4 0% 0% no-repeat padding-box;
}

.request-design-form-buildtype-pic {
    width: 150px;
    object-fit: contain;
}

.request-design-form-buildtype-name {
    margin-top: 10px;
    font-size: 12px;
}

.request-design-input-box {
    background: #fefefe 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #f1f1f1;
    border-radius: 23px;

    padding: 30px;
}

.request-design-input-title {
    text-align: center;
    padding: 20px 0 40px 0;
    font-size: 20px;
}

.request-design-input-size-title {
    font-size: 20px;
    text-align: center;
}

.request-design-input-size-guid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.request-design-input-size-box {
    margin: 30px 0;
}
.request-design-input-size-item {
    width: 30%;
}

.request-design-input-size-item.text-center {
    display: flex;
    justify-content: center;
}

.request-design-input-size-item.flex-flex {
    display: flex;
    justify-content: flex-start;
}

.request-design-size-measure {
    display: flex;
    width: 120px;
    height: 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
}

.request-design-size-measure-item {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.request-design-size-measure-item.active {
    background: #fff5c4 0% 0% no-repeat padding-box;
}

.request-design-upload-box {
    padding: 30px 0;
}

.drag-drop-pic {
    width: 80%;
    object-fit: contain;
    padding: 10px;
}

.drag-drop-input {
    width: 80%;
    min-height: 100px;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #f0f0f0;
    }
}

.drag-drop-input-pic {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drag-drop-input-delete {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 25px;
    height: 25px;
    background: red;
    border-radius: 50%;
    cursor: pointer;
}

.drag-drop-input-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.request-design-upload-container {
    display: flex;
    margin: 30px 0;
}
.request-design-upload-left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.request-design-upload-left-pic {
    width: 80%;
    object-fit: contain;
}

.request-design-upload-right {
    width: 50%;
}

.request-design-upload-right-input {
    margin: 20px 0;
}

.request-design-button-box {
    text-align: center;
}

.request-design-button {
    width: 260px;
    height: 50px;
    border: none;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 34px;
    cursor: pointer;
    font-size: 18px;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.google-robert {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.design_info_select_button {
    width: 60px;
    height: 30px;
    border: none;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .request-design-form-container {
        width: 90%;
    }
    .request-design-form-buildtype-list {
        width: 100px;
    }
    .request-design-form-buildtype-pic {
        width: 70px;
        object-fit: contain;
    }
    .request-design-input-size-guid {
        display: flex;
        flex-direction: column;
    }
    .request-design-input-size-item {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }
    .request-design-input-size-item.flex-flex {
        display: flex;
        justify-content: center;
    }
    .request-design-upload-container {
        display: flex;
        flex-direction: column;
    }

    .request-design-upload-right {
        width: 100%;
    }

    .request-design-upload-left {
        width: 100%;
    }

    .drag-drop-input {
        width: 100%;
    }
}
