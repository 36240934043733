.self_title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 30px;
}
.self_top {
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    .self_icons {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .self_icon_item {
            width: 25%;
            padding: 20px 20px;
            cursor: pointer;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 80%;
                object-fit: contain;
            }
            .self_icon_name {
                text-align: center;
                // padding: 10px;
                margin-top: 10px;
            }
        }

        .self_icon_item_select {
            background-color: #edc400;
        }
    }
}

.self_youtube {
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .self_youtube_title {
        padding: 20px;
        font-size: 18px;
        font-weight: 600;
    }
    .self_youtube_video {
        width: 100%;
    }
}

.self_bottom_title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 30px;
}
.self_bottom {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    .self_bottom_area {
        display: flex;
        .self_bottom_pic {
            width: 50%;
            padding: 20px;
        }
        .self_bottom_word {
            width: 50%;
            padding: 20px;
            .self_bottom_tip {
                display: flex;
                align-items: center;
                color: #ceae16;
                margin-bottom: 30px;
            }
        }
    }
}
.divider_mobile {
    display: none;
}
.divider_mobile_title {
    display: none;
}
@media (max-width: 768px) {
    .self_title {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding: 30px 30px;
    }
    .self_top {
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        .self_icons {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: space-between;

            .self_icon_item {
                width: 50%;
                padding: 20px 20px;
                cursor: pointer;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            .self_icon_item_select {
                background-color: #edc400;
            }
        }
    }

    .self_bottom_title {
        padding: 10px;
        margin-top: 20px;
    }
    .self_youtube {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        .self_youtube_title {
            padding: 20px;
            font-size: 18px;
            font-weight: 600;
        }
        .self_youtube_video {
            width: 100%;
        }
    }

    .self_bottom {
        width: 85%;
        margin-left: auto;
        margin-right: auto;

        .self_bottom_step {
            padding: 10px 0;
        }
        .self_bottom_area {
            display: flex;
            flex-direction: column;
            .self_bottom_pic {
                width: 100%;
                padding: 0px;
            }
            .self_bottom_word {
                width: 100%;
                padding: 0px;
                .self_bottom_tip {
                    display: flex;
                    align-items: center;
                    color: #ceae16;
                    margin-bottom: 10px;
                    margin-top: 20px;
                }
            }
        }
    }
    .divider_mobile {
        display: block;
        padding: 20px 0;
    }
    .divider_mobile_title {
        display: block;
    }
}
