.cabinetcare_info_container {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cabinetcare_info_box {
        width: 80%;
        .cabinetcare_info_title {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            padding: 30px;
        }
        .cabinetcare_info_detail {
            line-height: 2;
            ul > li {
                list-style: disc;
            }
        }

        .cabinetcare_info_split {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            .cabinetcare_info_split_pic {
                width: 48%;
                padding: 20px 20px 20px 0px;
            }
            .cabinetcare_info_split_word {
                width: 48%;
                display: flex;
                flex-direction: column;
                // align-items: center;
                line-height: 2;
            }
            ul > li {
                list-style: disc;
            }
        }

        .cabinetcare_info_split_reverse {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            .cabinetcare_info_split_pic {
                width: 48%;
                padding: 20px 20px 20px 0px;
            }
            .cabinetcare_info_split_word {
                width: 48%;
                display: flex;
                flex-direction: column;
                // align-items: center;
                line-height: 2;
            }
            ul > li {
                list-style: disc;
            }
        }

        .cabinetcare_info_header {
            font-size: 18px;
            font-weight: 600;

            padding: 30px 0;
        }
    }
}
@media (max-width: 768px) {
    .cabinetcare_info_container {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cabinetcare_info_box {
            width: 100%;
            .cabinetcare_info_title {
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                padding: 30px;
            }
            .cabinetcare_info_detail {
                line-height: 2;
                padding: 20px 30px;
            }

            .cabinetcare_info_split {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .cabinetcare_info_split_pic {
                    width: 100%;
                    padding: 20px 0;
                }
                .cabinetcare_info_split_word {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    line-height: 2;
                    padding: 20px 30px;
                }
            }

            .cabinetcare_info_split_reverse {
                display: flex;
                flex-direction: column;
                flex-flow: column-reverse;
                .cabinetcare_info_split_pic {
                    width: 100%;
                    padding: 20px 0;
                }
                .cabinetcare_info_split_word {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    line-height: 2;
                    padding: 20px 30px;
                }
            }

            .cabinetcare_info_header {
                font-size: 18px;
                font-weight: 600;

                padding: 30px 30px;
                text-align: center;
            }
        }
    }
}
