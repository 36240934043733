.product_container {
    padding: 20px 10px;
    .product_top {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .product_pic {
            width: 47%;
        }
        .product_info {
            width: 47%;
            .product_info_top {
                display: flex;
                justify-content: space-between;
                .product_info_name {
                    width: 70%;
                    font-size: 20px;
                }
                .product_info_price {
                    width: 30%;
                    font-size: 24px;
                    font-weight: 600;
                    text-align: right;
                }
            }
            .product_info_size {
                font-size: 14px;
                margin: 10px 0;
            }
            .product_info_skuno {
                font-size: 12px;
                color: #9d9d9d;
            }
            .product_info_available {
                margin-top: 30px;
                margin-bottom: 10px;
                .product_info_available_in {
                    color: #1ea834;
                }
                .product_info_available_out {
                    color: red;
                }
            }
            .product_info_color {
                width: 100%;
                height: 220px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .product_info_color_box {
                    margin-top: 10px;
                    display: flex;
                    .product_info_color_item {
                        width: 55px;
                        height: 40px;
                        border: 1px solid #ccc;
                        margin-right: 20px;
                        cursor: pointer;
                    }
                }
            }
            .product_info_add_button {
                button {
                    width: 80%;
                    height: 50px;
                    background: #fddc3a 0% 0% no-repeat padding-box;
                    border-radius: 36px;
                    border: none;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 600;
                    &:hover {
                        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
                    }
                }
            }
        }
    }
    .product_bottom {
        display: flex;
        justify-content: space-between;
        .product_bottom_left {
            width: 48%;
        }
        .product_bottom_right {
            width: 48%;
            .product_bottom_right_word {
                display: flex;
                align-items: center;
                padding: 5px 0;
            }
            .product_bottom_right_button {
                margin-top: 30px;
                button {
                    width: 300px;
                    height: 35px;
                    background: #fff5c4 0% 0% no-repeat padding-box;
                    border-radius: 36px;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}

.product_similer_title {
    font-size: 18px;
    font-weight: 600;
}
@media (max-width: 768px) {
    .product_container {
        padding: 10px 20px;
        .product_top {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .product_pic {
                width: 100%;
            }
            .product_info {
                width: 100%;
                .product_info_color {
                    width: 100%;
                    height: auto;
                    margin: 30px 0;
                    .product_info_color_box {
                        .product_info_color_item {
                        }
                    }
                }
                .product_info_add_button {
                    display: flex;
                    justify-content: center;
                    button {
                        width: 100%;
                        height: 50px;
                        background: #fddc3a 0% 0% no-repeat padding-box;
                        border-radius: 36px;
                        border: none;
                    }
                }
            }
        }
        .product_bottom {
            display: flex;
            flex-direction: column-reverse;
            padding: 10px 0;
            .product_bottom_left {
                width: 100%;
            }
            .product_bottom_right {
                width: 100%;
                .product_bottom_right_word {
                    font-size: 14px;
                }
                .product_bottom_right_button {
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    justify-content: center;
                    button {
                        width: 90vw;
                        height: 50px;
                        background: #fff;
                        border-radius: 36px;
                        border: 1px solid #707070;
                    }
                }
            }
        }
    }
}
