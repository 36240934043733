.style_header_container {
    position: relative;
    top: -26px;
    width: 100%;

    .style_header_box {
        display: flex;

        .style_header_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 50px;

            p {
                font-size: 9px;
            }
        }

        .style_header_item_full {
            display: block;
            margin: 20px 50px;
        }
    }
}

@media (max-width: 768px) {
    .style_header_container {
        position: relative;
        top: 0;
        width: 100%;
        // background: #d6d6d6;

        .style_header_box {
            .style_header_item_full {
                display: none;
            }
            display: flex;
            justify-content: space-evenly;

            .style_header_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 20px 0;
            }
        }
    }
}
