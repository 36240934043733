.faq_title {
    padding: 30px 0;
    font-size: 18px;
    font-weight: 600;
}
.faq_search {
    width: 50%;
    display: flex;
    align-items: flex-end;
}

.faq_item_box {
    padding: 30px 0;
    display: flex;
    .faq_category {
        width: 20%;
        font-size: 18px;
        font-weight: 600;
    }
    .faq_info {
        width: 80%;
    }
}

.faq_item_list_container {
    .faq_item_list_box {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        // padding: 10px 0;
        margin-bottom: 10px;
        .faq_item_list_title {
        }
        .faq_item_list_icon {
            display: none;
        }
    }
    .faq_item_list_info {
        font-size: 14px;
        margin: 10px 0 20px 0;
        white-space: pre-line;
    }
}

@media (max-width: 768px) {
    .faq_title {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
    .faq_search {
        width: 100%;
    }

    .faq_item_box {
        display: flex;
        flex-direction: column;
        .faq_category {
            width: 100%;

            margin-bottom: 10px;
        }
        .faq_info {
            width: 100%;
        }
    }
    .faq_item_list_container {
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        .faq_item_list_box {
            .faq_item_list_icon {
                display: block;
            }
        }
    }
}
