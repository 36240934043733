.recommend_item_box {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    .recommend_item_pic {
        img {
            width: 100%;
            height: 250px;
            object-fit: contain;
            cursor: pointer;
        }
    }
    .recommend_item_info {
        .recommend_item_title {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            .recommend_item_title_left {
                .recommend_item_title_name {
                    font-size: 14px;
                }
                .recommend_item_title_size {
                    font-size: 10px;
                    padding: 5px 0;
                }
            }
            .recommend_item_title_price {
                text-align: right;
                font-size: 16px;
                font-weight: 600;
            }
        }
        .recommend_item_button {
            button {
                width: 120px;
                height: 40px;
                background: #fddc3a 0% 0% no-repeat padding-box;
                border-radius: 11px;
                border: none;
                cursor: pointer;
                &:hover {
                    box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .recommend_item_box {
        padding: 0 25px;
        .recommend_item_pic {
            img {
                width: 100%;
                height: 150px;
                object-fit: contain;
            }
        }
        .recommend_item_info {
            .recommend_item_title {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .recommend_item_title_left {
                    height: 60px;
                    .recommend_item_title_name {
                        text-align: left;
                        font-size: 12px;
                    }
                    .recommend_item_title_size {
                        text-align: left;
                        font-size: 9px;
                    }
                }
                .recommend_item_title_price {
                    text-align: left;
                }
            }
            .recommend_item_button {
                display: flex;
                justify-content: flex-start;
                button {
                    width: 120px;
                    height: 40px;
                    background: #fddc3a 0% 0% no-repeat padding-box;
                    border-radius: 11px;
                }
            }
        }
    }
}
