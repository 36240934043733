.layout-box {
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.layout-nav {
    width: 200px;
    margin-right: 30px;
}

.layout-children {
    width: 100%;
}

.layout-nav-box {
    width: 100%;
}

.layout-nav-item-box {
    padding: 18px 10px;
    width: 100%;
    display: flex;
    align-items: center;

    border-radius: 7px;
    cursor: pointer;
}

.layout-nav-item-icon {
    width: 50px;
    text-align: center;
}

@media (max-width: 768px) {
    .layout-box {
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: space-between;
        margin-top: 0px;
    }

    .layout-nav {
        width: 100%;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
    }

    .layout-children {
        width: 100%;
    }

    .layout-nav-box {
        width: 100%;
        display: flex;
    }

    .layout-nav-item-box {
        padding: 20px 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        border-radius: 0px;
    }

    .layout-nav-item-icon {
        width: 50px;
        text-align: center;
    }
    .layout-nav-item-name {
        margin-top: 10px;
        width: 50px;
        text-align: center;
        font-weight: 600;
    }
}
