.sample_title {
    width: 50%;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 30px 0;
    margin-left: auto;
    margin-right: auto;
}
.sample_description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.sample_style {
    margin: 30px 0;
    width: 700px;
    height: 59px;
    background: none;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    .sample_style_item {
        width: 300px;
        height: 50px;
        background: #fff;
        border: 1px solid #000;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.sample_cabinet_box {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .sample_cabinet_left {
        width: 30%;
        .sample_cabinet_left_img {
            text-align: center;
            img {
                height: 100px;
                object-fit: contain;
            }
        }
        .sample_cabinet_left_name {
            font-size: 12px;
            text-align: center;
        }
    }
    .sample_cabinet_middle {
        width: 30%;
        .sample_cabinet_middle_title {
            font-size: 12px;
        }
        .sample_cabinet_middle_finish_box {
            margin-top: 20px;
            width: 120px;
            display: flex;
            flex-wrap: wrap;

            .sample_cabinet_middle_finish_item {
                width: 30px;
                height: 30px;
                border: 1px solid #ccc;
                border-radius: 50%;
                margin-right: 10px;
                cursor: pointer;
                margin-top: 10px;
            }
        }
    }
    .sample_cabinet_right {
        width: 30%;
        .sample_cabinet_right_price {
        }
        .sample_cabinet_right_shipping {
            color: #de0303;
            font-size: 12px;
            margin-top: 10px;
        }
        .sample_cabinet_right_refund {
            font-size: 12px;
            margin-top: 10px;
        }
        .sample_cabinet_right_button {
            margin-top: 10px;
            button {
                width: 140px;
                height: 35px;
                background: #fddc3a 0% 0% no-repeat padding-box;
                border-radius: 22px;
                border: none;
                cursor: pointer;
            }
        }
    }
}
.sample_countertop_container {
    display: flex;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}
.sample_countertop_box {
    width: 40%;
    margin-bottom: 50px;
    .sample_countertop_item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .sample_countertop_item_left {
            text-align: center;
            img {
                height: 100px;
                object-fit: contain;
            }
            p {
                font-size: 12px;
                margin-top: 10px;
            }
        }
        .sample_countertop_item_right {
            .sample_countertop_right_price {
            }
            .sample_countertop_right_shipping {
                color: #de0303;
                font-size: 12px;
                margin-top: 10px;
            }
            .sample_countertop_right_refund {
                font-size: 12px;
                margin-top: 10px;
            }
            .sample_countertop_right_button {
                margin-top: 30px;
                button {
                    width: 140px;
                    height: 35px;
                    background: #fddc3a 0% 0% no-repeat padding-box;
                    border-radius: 22px;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .sample_style {
        margin: 30px 0;
        width: 300px;
        height: 40px;
        background: #f0f0f0;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        .sample_style_item {
            width: 150px;
            height: 40px;
            background: #f0f0f0;
            border: none;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }
    }

    .sample_cabinet_box {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        .sample_cabinet_left {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 20px;
            .sample_cabinet_left_img {
                text-align: center;
                img {
                    height: 150px;
                    object-fit: contain;
                }
            }
            .sample_cabinet_left_name {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 20px;
            }
        }
        .sample_cabinet_middle {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .sample_cabinet_middle_title {
                font-size: 12px;
            }
            .sample_cabinet_middle_finish_box {
                margin-top: 20px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .sample_cabinet_middle_finish_item {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
        }
        .sample_cabinet_right {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            .sample_cabinet_right_price {
            }
            .sample_cabinet_right_shipping {
                color: #de0303;
                font-size: 12px;
                margin-top: 10px;
            }
            .sample_cabinet_right_refund {
                font-size: 12px;
                margin-top: 10px;
            }
            .sample_cabinet_right_button {
                margin-top: 10px;
                button {
                    width: 200px;
                    height: 35px;
                    background: #fddc3a 0% 0% no-repeat padding-box;
                    border-radius: 22px;
                    border: none;
                }
            }
        }
    }

    .sample_countertop_container {
        display: flex;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
    .sample_countertop_box {
        width: 100%;
        margin-bottom: 0px;
        .sample_countertop_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            .sample_countertop_item_left {
                text-align: center;
                display: flex;
                flex-direction: column-reverse;
                img {
                    height: 100px;
                    object-fit: contain;
                }
                p {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
            }
            .sample_countertop_item_right {
                margin: 20px 0;
                text-align: center;
                .sample_countertop_right_price {
                }
                .sample_countertop_right_shipping {
                    color: #de0303;
                    font-size: 12px;
                    margin-top: 10px;
                }
                .sample_countertop_right_refund {
                    font-size: 12px;
                    margin-top: 10px;
                }
                .sample_countertop_right_button {
                    margin-top: 30px;
                    button {
                        width: 180px;
                        height: 40px;
                        background: #fddc3a 0% 0% no-repeat padding-box;
                        border-radius: 22px;
                        border: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
