.comingsoon-header-container {
    position: relative;
    display: flex;
    top: -30px;
    width: 100%;
    height: 100%;
    padding-bottom: 30%;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/v1631629392/Pictures_Kitchen/SW-F-Kitchen_1/Hero_Image_ftzaqx.webp');
    background-position: center;
    justify-content: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comingsoon-header-container::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}

.comingsoon-header-text-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
        font-family: proxima-nova, sans-serif;
        font-style: regular;
        font-weight: 600;
        font-size: 3rem;
    }
    p {
        padding: 30px 0;
    }
    button {
        width: 148px;
        height: 33px;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        }
    }
}

@media (max-width: 768px) {
    .comingsoon-header-container {
        top: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 70%;
        margin-bottom: 30px;
    }
    .comingsoon-header-text-box {
        h3 {
            font-size: 2rem;
        }
    }
}
