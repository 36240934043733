.addToCarts-lists {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addToCart-list-box {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0;
    border-bottom: 1px solid #f3f3f3;
}

.addToCart-list-pic {
    width: 20%;
    padding: 0 10px;

    img {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }
}

.addToCart-list-name-box {
    width: 50%;
    .addToCart-list-name-name {
        height: 30px;
        font-size: 14px;
    }
    .addToCart-list-name-size {
        font-size: 12px;
    }
}

.addToCart-unit-price {
    font-size: 12px;
    padding: 5px 0;
}

.addToCart-list-name-skuno {
    font-size: 12px;
    color: #9d9d9d;
    margin-bottom: 5px;
}

.addToCart-list-qty-area {
    width: 15%;
}

.addToCart-list-right {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 700;
}

.addToCart-promo-price {
    width: 15%;
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-end;
}

.addToCart-list-name-remove {
    font-size: 12px;
    margin-top: 10px;
}

.addToCart-list-qty {
    padding-left: 0;
}

@media (max-width: 768px) {
    .addToCart-list-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f3f3f3;
    }

    .addToCart-list-pic {
        width: 50%;
        padding: 0 10px;

        img {
            width: 100%;
            height: 100px;
            object-fit: contain;
        }
    }

    .addToCart-list-name-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
    }

    .addToCart-list-qty-area {
        width: 50%;
        text-align: center;
    }

    .addToCart-list-right {
        width: 50%;
        justify-content: center;
        padding: 10px 0;
    }
    .addToCart-promo-price {
        width: 50%;
        padding: 10px 0;
        justify-content: center;
        align-items: center;
    }
}
