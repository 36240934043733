.mycart-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 20px;
}
.carts-items-count {
    text-align: center;
    margin-bottom: 20px;
}
.carts-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .carts-lists {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .carts-total-box {
        width: 25%;
        margin-right: 10px;

        .carts-total {
            width: 100%;
            border: 1px solid #c3c1c0;
            border-radius: 4px;
            padding: 20px;

            .carts-total-item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                .carts-total-span-left {
                    font-size: 14px;
                }
                .carts-total-span-right {
                    font-size: 14px;
                    text-align: right;
                }
            }
            .carts-total-button {
                text-align: center;
                margin-top: 30px;
                button {
                    width: 200px;
                    height: 40px;
                    background: #fddc3a 0% 0% no-repeat padding-box;
                    border-radius: 36px;
                    font-weight: 600;
                    border: none;
                    cursor: pointer;
                    &:hover {
                        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
                    }
                }
            }
        }
    }
}

.cart-list-box {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    .cart-list-pic {
        width: 20%;
        img {
            width: 100%;
            height: 100px;
            object-fit: contain;
        }
    }
    .cart-list-name-box {
        width: 40%;
        .cart-list-name-name {
            height: 40px;
        }
        .cart-list-name-size {
            font-size: 12px;
        }
        .cart-list-name-remove {
            margin-top: 30px;
            font-size: 14px;
        }
    }
    .cart-list-right {
        width: 30%;
        display: flex;
        .cart-list-qty {
            width: 50%;
        }
        .cart-list-price {
            width: 50%;
            padding-top: 10px;
            font-weight: 600;
        }
    }
}

.cart-list-promo-delete {
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    margin-top: 10px;
}

.cart-list-option-title {
    font-size: 12px;
}

.cart-list-option-detail {
    font-size: 12px;
    color: #9d9d9d;
}

.cart_recommend_title {
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0;
}

.cart-list-name-skuno {
    font-size: 12px;
    color: #9d9d9d;
    margin-bottom: 5px;
}

.carts-print {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carts-promo-code {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
}
.carts-promo-code-left {
    width: 150px;
    text-align: right;
    margin-right: 10px;
}

.promo-code-box {
    display: flex;
}
.promo-code-button {
    border: none;
    background: #ffffff;
    cursor: pointer;
}
@media (max-width: 768px) {
    .mycart-title {
    }
    .carts-items-count {
    }
    .carts-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .carts-lists {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .carts-total-box {
            width: 100%;
            border-top: 1px solid #ccc;
            margin-right: 0px;

            .carts-total {
                width: 100%;
                border: none;
                border-radius: 0;
                padding: 20px;

                .carts-total-item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    .carts-total-span-left {
                        font-size: 14px;
                    }
                    .carts-total-span-right {
                        font-size: 14px;
                        text-align: right;
                    }
                }
                .carts-total-button {
                    text-align: center;
                    margin-top: 30px;
                    button {
                        width: 250px;
                        height: 50px;
                        background: #fddc3a 0% 0% no-repeat padding-box;
                        border-radius: 36px;
                        border: none;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .cart-list-box {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;
        border-top: 1px solid #ccc;
        padding-top: 30px;
        .cart-list-pic {
            width: 20%;
            img {
                height: 80px;
                object-fit: contain;
            }
        }
        .cart-list-name-box {
            width: 40%;
            .cart-list-name-name {
                height: 40px;
            }
            .cart-list-name-size {
                font-size: 12px;
            }
            .cart-list-name-remove {
                margin-top: 30px;
                font-size: 14px;
            }
        }
        .cart-list-right {
            width: 20%;
            display: flex;
            flex-direction: column-reverse;
            .cart-list-qty {
                width: 100%;
                margin-top: 45px;
            }
            .cart-list-price {
                padding-top: 0px;
                font-weight: 600;
                text-align: right;
            }
        }
    }
    .cart_recommend_title {
        text-align: center;
    }
}
