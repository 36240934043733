.countertop-header {
    position: relative;
    top: -23px;
    display: flex;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    margin-bottom: 30px;
}

.countertop-bgpic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/v1631539379/Products/Countertop/7_eazrfe.webp');
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.countertop-bgpic::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    // z-index: -1;
}

.countertop_text_box {
    // padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.countertop_text_area {
    width: 450px;
    color: #fff;
    h3 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 3em;
        text-align: center;
        padding: 0px 0 20px 0;
    }
    p {
        text-align: center;
        font-size: 18px;
        line-height: 25px;
        word-wrap: wrap;
    }
}

.countertop-details {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.countertop-details-pic {
    width: 48%;
}

.countertop-details-pic-bg {
    width: 100%;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/v1650572713/Pictures_Kitchen/SG-F-Kitchen_2/kitchen_lait_grey_shaker-1-min_xjj9d3.webp');
    background-position: top;
    background-size: cover;
    aspect-ratio: 16/9;
}

.countertop-details-info {
    width: 48%;
}

.countertop-details-info-header {
    font-size: 20px;
    font-weight: bold;
}

.countertop-details-info-body {
    margin-top: 30px;
}

.countertop-products-header {
    padding: 30px 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.countertop-products {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
}

.countertop-product {
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
}

.countertop-product-text-box {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 60%;
    height: 40px;
    background-color: #edc400;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.countertop-product-text-header {
    font-size: 12px;
}

.countertop-product-text-body {
    font-size: 10px;
}

.countertop-product-more {
    text-align: center;
    padding: 30px 0;
}

.countertop-product-more-qty {
    font-size: 10px;
}

.countertop-product-more-link {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 768px) {
    .countertop_text_area {
        width: 90%;
    }
    .countertop-products {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
    .countertop-details {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .countertop-details-pic {
        width: 100%;
    }

    .countertop-details-info {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    .countertop-product-text-box {
        width: 80%;
    }
}
