.continue-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.continue-login {
    width: 45%;
}

.continue-guest {
    width: 45%;
}

.continue-line {
    width: 1px;
    background: #000;
}

.continue-guest-title {
    padding: 30px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.continue-guest-button-box {
    display: flex;
    justify-content: center;
    margin: 30px 0;
}

.continue-guest-button {
    width: 150px;
    height: 40px;
    background: #edc400 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .continue-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .continue-login {
        width: 100%;
    }

    .continue-guest {
        width: 100%;
    }

    .continue-line {
        width: 100%;
        height: 1px;
    }
}
