.paymentCharge-header {
    text-align: center;
    font-size: 2rem;
}

.paymentCharge-subtitle {
    width: 100%;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    height: 50px;
    margin-top: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.readonly-payment-information__details__items--separator {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: #6c90a2;
    vertical-align: middle;
    margin: 0 10px;
}

.paymentCharge-subtitle-box {
    // justify-content: space-evenly;
}

.invoice-information {
    font-size: 18px;
    color: #6c90a2;
    display: inline-block;
    vertical-align: middle;
}

.paymentCharge-cc-input {
    margin-top: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
}

.paymentCharge-cc-input-header {
    background-color: #f7f7f7;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    align-items: center;
}

.paymentCharge-cc-input-box {
    padding: 10px;
}

.paymentCharge-cc-icons {
    display: flex;
    justify-content: center;
}

.paymentCharge-cc-icon {
    margin: 0 5px;
}

.paymentCharge-cc-button-box {
    text-align: center;
    margin-top: 20px;
}

.paymentCharge-cc-button {
    width: 60%;
    height: 50px;
    background: #eed867 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.paymentCharge-cc-button:disabled,
.paymentCharge-cc-button[disabled] {
    border: 1px solid #999999;
    background-color: #fef9de;
    color: #666666;
}

@media (max-width: 768px) {
    .paymentCharge-header {
        font-size: 22px;
    }
    .paymentCharge-subtitle {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        height: 120px;
    }
}
