.price-match-box {
    width: 100%;
    display: flex;
    justify-content: center;
}

.price-match-item {
    max-width: 800px;
}

.price-match-title {
    padding: 20px;
}

.price-match-firstline {
    display: flex;
}

.price-match-input-item {
    width: 100%;
    // padding: 20px 0;
}

.price-match-drag-drop-input-pic {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-match-drag-drop-input {
    margin-top: 10px;
    width: 100%;
    min-height: 50px;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        background: #f0f0f0;
    }
}

.price-match-drag-drop-input-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.price-match-drag-drop-pic {
    width: 300px;
    object-fit: contain;
    padding: 10px;
}

.price-match-drag-drop-input-delete {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 25px;
    height: 25px;
    background: red;
    border-radius: 50%;
    cursor: pointer;
}

.price-match-upload {
    padding: 0 20px;
    margin-top: 10px;
}

.price-match-button-box {
    padding: 20px;
}

.price-match-button {
    width: 260px;
    height: 50px;
    border: none;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 34px;
    cursor: pointer;
    font-size: 18px;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .price-match-firstline {
        display: flex;
        flex-direction: column;
    }

    .price-match-item {
        max-width: 90%;
    }

    .price-match-title {
        padding: 0px;
    }

    .price-match-button-box {
        padding: 20px;
        display: flex;
        justify-content: center;
    }
    .price-match-button {
        width: 80%;
    }
}
