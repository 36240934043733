.plan_section_one {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .plan_section_one_pic {
        width: 30%;
        padding: 20px;
    }
    .plan_section_one_detail {
        width: 30%;
        height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;
        padding: 20px;
        .plan_section_one_detail_title {
            font-size: 20px;
            font-weight: 700;
        }
        .plan_section_one_button {
            width: 300px;
            height: 40px;
            background: #fddc3a 0% 0% no-repeat padding-box;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
            }
        }
    }
}

.plan_section_two {
    margin-top: 50px;
    .plan_section_two_pic {
        position: relative;
        width: 100%;
        height: 0px;
        padding-bottom: 30%;

        .plan_section_two_box {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .plan_section_two_title_box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h2 {
                    text-align: center;
                    font-family: 'Lobster';
                    font-size: 3rem;

                    color: #fddc3a;
                }
                p {
                    width: 50%;
                    text-align: center;
                    color: white;
                    margin: 30px 0;
                }
                .plan_section_two_button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    height: 50px;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border-radius: 35px;
                    font-weight: 600;
                }
            }
        }
    }
}

.planner-designer-container {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin-top: 60px;
}

.planner-designer-left {
    width: 50%;
}

.planner-designer-img {
    width: 100%;
    height: auto;
    object-fit: contain;
    box-shadow: 0px 3px 5px rgba(97, 105, 109, 0.5);
}

.planner-designer-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    // align-items: center;
    h2 {
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        letter-spacing: 0px;
        color: #fddc3a;
        opacity: 1;
    }
    p {
        padding: 30px 0;
    }
    button {
        width: 300px;
        height: 40px;
        border: none;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        cursor: pointer;
        font-size: 18px;
        margin-top: 30px;
        &:hover {
            box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        }
    }
}

@media (max-width: 768px) {
    .plan_section_one {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .plan_section_one_pic {
            width: 100%;
        }
        .plan_section_one_detail {
            width: 100%;
            height: 260px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .plan_section_one_detail_title {
                text-align: center;
                font-size: 20px;
                font-weight: 700;
            }
            .plan_section_one_button {
                width: 300px;
                height: 40px;
                background: #fddc3a 0% 0% no-repeat padding-box;
                border-radius: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .plan_section_two {
        margin-top: 50px;
        .plan_section_two_pic {
            position: relative;
            width: 100%;
            height: 0px;
            padding-bottom: 100%;

            .plan_section_two_box {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                .plan_section_two_title_box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    h2 {
                        text-align: center;
                        font-family: 'Lobster';
                        font-size: 1.2rem;
                        color: #fddc3a;
                    }
                    p {
                        width: 250px;
                        text-align: center;
                        color: white;
                        font-size: 12px;
                        margin: 30px 0;
                    }
                    .plan_section_two_button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 300px;
                        height: 50px;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        border-radius: 35px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .planner-designer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        margin-top: 60px;
    }

    .planner-designer-left {
        width: 100%;
    }
    .planner-designer-right {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 0;
        margin-top: 30px;
        h2 {
            font-size: 23px;
        }
    }
}
