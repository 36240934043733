.style_title {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .style_title_line {
        width: 50px;
    }
    .style_title_word {
        font-weight: 700;
        font-size: 30px;
        margin: 20px 30px;
    }
}

.style_item_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 70px 0;
}

.style_item_box_reverse {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 70px 0;
}

.style_item_pic {
    width: 60%;
    margin: 0 10px;
}

.style_item_detail.reverse {
    padding-left: 30px;
}

.style_item_detail {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.7em;
        color: $main_color;
    }
    .style_item_detail_des {
        width: 100%;
        text-align: left;
        margin: 30px 0;
        padding: 10px;
        flex: 1;
    }

    .style_item_detail_color {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-size: 18px;
        font-weight: 600;
        padding: 10px;
    }
    .style_item_detail_colors {
        width: 100%;
        display: flex;
        // justify-content: space-evenly;
        flex-wrap: wrap;

        .style_item_detail_color_box {
            width: 100px;
            height: 200px;
            // box-shadow: 0px 3px 6px #00000029;
            // border: 1px solid #d6d6d6;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            margin: 5px;
            cursor: pointer;
            // transition: transform 1s ease-out;
            &:hover {
                box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
                transition: box-shadow 0.5s ease-in-out;
            }
            img {
                width: 80px;
                height: 140px;
                object-fit: contain;
            }

            p {
                font-size: 12px;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}

.style_item_detail_color.reverse {
    display: flex;
    justify-content: flex-end;
}

.style_item_detail_colors.reverse {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .style_title {
        display: none;
    }
    .style_item_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 30px 0;
    }

    .style_item_box_reverse {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        padding-top: 20px;
    }

    .style_item_pic {
        margin: 30px 0;
        width: 100%;
    }

    .style_item_detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-family: proxima-nova, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 1.5em;
            color: $main_color;
        }
        .style_item_detail_des {
            width: 60%;
            text-align: left;
            margin: 30px 0;
            flex: 1;
        }
        .style_item_detail_color {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .style_item_detail_colors {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: nowrap;

            .style_item_detail_color_box {
                width: 80px;
                height: 150px;
                // box-shadow: 0px 3px 6px #00000029;
                // border: 1px solid #d6d6d6;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px;
                img {
                    width: 50px;
                    height: 100px;
                    object-fit: contain;
                    cursor: pointer;
                }
                p {
                    font-size: 10px;
                    text-align: center;
                }
            }
        }
    }
}
