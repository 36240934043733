.bogo_container {
    position: relative;
    top: -30px;
    display: flex;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    // margin: 10px 0;
}

.bogo_bgpic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/v1660158096/New%20Website%20lookout%20%28for%20yanni%29/Cabient/pexels-mark-mccammon-2724749_pxs6uo.webp');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
}
.bogo_bgpic::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    // z-index: -1;
}

.bogo_text_box {
    // padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.bogo_text_area {
    width: 600px;
    h3 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 2.2em;
        color: $main_color;
        text-align: center;
        padding: 0px 0 30px 0;
    }
    p {
        color: white;
        text-align: center;
        font-size: 18px;
        line-height: 25px;
    }
}

.bogo_detail_box {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 50px;
}

.bogo_detail_left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 50px;
}

.bogo_detail_right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 50px;
}

.bogo_detail_header {
    font-size: 22px;
    font-weight: bold;
}

.bogo_detail_sub_title {
    font-size: 16px;
    width: 320px;
    margin-top: 20px;
    font-weight: 600;
}

.bogo_detail_text {
    font-size: 14px;
    margin-top: 20px;
}

.bogo_detail_big_text {
    font-size: 16px;
    margin-top: 20px;
}

.bogo_detail_button {
    width: 230px;
    height: 40px;
    background-color: #fddc3a;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.bogo_pic {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.bogo_bottom_box {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    margin-top: 30px;
    // margin: 10px 0;
}

.bogo_detail_button_box {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
}

.bogo_bottom_bgpic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/v1632580993/Pictures_Kitchen/SW-F-Kitchen_1/SW_a_vqic6i.webp');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.bogo_bottom_bgpic::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    // z-index: -1;
}

.bogo_bottom_text_box {
    width: 100%;
    display: flex;
    z-index: 10;
    padding: 0px 80px;
}

.bogo_bottom_text_area {
    width: 70%;
    padding: 0 20px;
    h3 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 2.2em;
        color: #fddc3a;

        padding: 30px 0 30px 0;
    }
    p {
        color: white;

        font-size: 14px;
        line-height: 25px;
    }
}

.bogo_bottom_button_area {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bogo_bottom_button {
    width: 300px;
    height: 40px;
    background-color: #fddc3a;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.bogo_bottom_text_line {
    margin-top: 30px;
    color: #fddc3a;
    font-weight: 600;
}

.bogo_bottom_text_line_2 {
    color: #fddc3a;
    margin-top: 10px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .bogo_container {
        top: 0;
        min-height: 500px;
    }

    .bogo_text_area {
        width: 100%;
        h3 {
            // font-family: Oswald;
            font-family: proxima-nova, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 1.8em;
            color: $main_color;
            text-align: center;
            padding: 30px 0 30px 0;
        }
        p {
            color: white;
            text-align: center;
            font-size: 18px;
            line-height: 25px;
        }
    }

    .bogo_detail_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 0px;
    }

    .bogo_detail_box:nth-child(odd) {
        flex-direction: column-reverse;
    }

    .bogo_detail_left {
        width: 100%;
        padding: 0;
        align-items: center;
    }

    .bogo_detail_right {
        width: 100%;
        align-items: center;
        padding-left: 0px;
    }

    .bogo_pic {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .bogo_pic_cover {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .bogo_detail_header {
        text-align: center;
        margin-top: 20px;
    }

    .bogo_detail_sub_title {
        width: 100%;
        padding: 20px;
        text-align: center;
    }

    .bogo_detail_text {
        margin: 20px;
        text-align: center;
    }

    .bogo_detail_button {
        margin: 10px 0;
    }

    .bogo_bottom_box {
        min-height: 600px;
    }

    .bogo_bottom_text_box {
        width: 100%;
        display: flex;
        flex-direction: column;

        padding: 20px;
    }

    .bogo_bottom_text_area {
        width: 100%;
        text-align: center;
        h3 {
            font-size: 2em;
        }
    }

    .bogo_bottom_button_area {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bogo_bottom_button {
        width: 300px;
        height: 40px;
        background-color: #fddc3a;
        border: none;
        cursor: pointer;
        border-radius: 5px;
    }

    .bogo_bottom_text_line {
        margin-top: 30px;
        color: #fddc3a;
    }

    .bogo_bottom_text_line_2 {
        color: #fddc3a;
        margin-top: 20px;
    }
}
