.carousel-box {
    position: relative;
    display: flex;
    top: -23px;
    width: 100%;
    height: 500px;
    // min-height: 500px;
    justify-content: center;
}
.home_big_pic_full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // padding-bottom: 25%;
    z-index: -1;
    transition: all 1s ease-in-out;
}

.home_big_pic_full::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(255, 255, 255, 0.1)
    );
}

.slug-container {
    width: 100%;
    // max-width: 1280px;
    display: flex;
    justify-content: center;
}

.slug-text {
    width: 700px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    h2 {
        // font-family: 'Oswald';
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
        // letter-spacing: 5px;
        font-size: 3em;
        color: white;
        // margin-top: 10px;
        z-index: 3;
        text-transform: uppercase;
        text-align: center;
    }
    p {
        // font-family: 'Arimo';
        // font-style: italic;
        font-family: proxima-nova, sans-serif;
        font-style: regular;
        font-weight: 100;
        font-size: 25px;
        // letter-spacing: 5px;
        color: white;
        margin-top: 20px;
        text-align: center;
    }
    .home_big_button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-top: 50px;
        width: 330px;
        height: 48px;
        background: #fddc3a 0% 0% no-repeat padding-box;
        opacity: 0.85;
        cursor: pointer;
        .home_big_button_text {
            display: flex;
            align-items: center;
            font-weight: 600;
        }
    }
}
.slug-count {
    position: absolute;
    bottom: 50px;
    display: flex;
    justify-content: center;
}
.slug-count-dot {
    width: 8px;
    height: 8px;
    margin: 0 10px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
}
.slug-count-dot-long {
    position: relative;
    width: 50px;
    height: 8px;
    margin: 0 10px;
    padding: 0 20px;
    background-color: #fddc3a;
}
.slug-count-dot-long::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 0;
    left: -4px;
    background-color: #fddc3a;
    border-radius: 50%;
}
.slug-count-dot-long::after {
    content: '';
    position: absolute;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 0;
    right: -4px;
    background-color: #fddc3a;
    border-radius: 50%;
}
.home_big_pic_back {
    // height: 25px;
}
.home_page_ad {
    position: relative;
    top: -30px;
    background-color: #a78d10;
}

.home_page_ad_text {
    height: 40px;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    // padding;
}
.home_pic_point_title_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .home_pic_point_yellow_line {
        width: 120px;
        border: 2px solid #edc400;
    }
}

.home_small_pic {
    position: relative;
    width: 100%;
    height: 70%;
    padding-bottom: 70%;
}

.home_small_bottom {
    width: 100%;
    height: 30%;
}

.home_big_pic_item {
    width: 32%;
    // height: 30vw;
}

.home_small_title_box {
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.5);
    // border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;

    z-index: 2;
    // &:hover {
    //     box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    // }
    h2 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5em;
        color: white;
    }
    p {
        color: white;
        margin-top: 1rem;
    }
}

.help_icon_style {
    color: $main_color;
}

.home_help_area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    .home_help_box {
        width: 60%;
        display: flex;
        justify-content: space-evenly;
        .home_help_icon {
            width: 150px;
            height: 120px;
            // border: 1px solid #ccc;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
            }
            .home_help_name_mobile {
                display: none;
            }
        }
        .home_help_name {
            display: block;
            // text-align: center;
            margin-top: 20px;
            display: flex;
            // justify-content: center;
        }
    }
}

.home_help_inside {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_big_pic_box {
    display: flex;
    justify-content: space-between;
}

.home_pic_point_box {
    display: flex;
    justify-content: space-between;
}

.home_pic_point_item {
    width: 48%;
}

.home_buying_container {
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0 20px 0;
}

.home_buying_left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_buying_logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.home_buying_right {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.home_buying_right_box {
    width: 45%;
    h3 {
        // font-family: 'Oswald';
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #a78d10;
        padding-bottom: 20px;
    }
    p {
        font-family: 'Arimo';
    }
}

.home_design_center_container {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    margin: 20px 0;
}

.home_design_center_bgpic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/v1650685245/Home_page/Design_Center_v6nplc.webp');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.home_design_center_bgpic::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    // z-index: -1;
}

.home_design_center_text_box {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.home_design_center_text_logo_box {
    margin-top: 30px;
}

.home_design_center_text_logo {
    width: 250px;
    object-fit: contain;
}
.home_design_center_text_area {
    width: 550px;
    h3 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 2.5em;
        color: white;
        text-align: center;
        padding: 30px 0 30px 0;
    }
    p {
        color: white;
        text-align: center;
        font-size: 18px;
        line-height: 25px;
    }
}

.home_design_center_button_box {
    display: flex;
    justify-content: space-evenly;
    width: 800px;
    color: white;
    align-items: center;
    margin-top: 50px;
}

.home_design_center_button {
    width: 300px;
    height: 40px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    border: none;
    font-size: 16px;
    cursor: pointer;
    // &:hover {
    //     box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    // }
}

.home_items_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px 0 50px 0;
}

.home_items_box {
    width: 20%;
    padding: 20px;
    text-align: center;
    img {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    h5 {
        font-size: 18px;
        padding: 10px 0;
        height: 80px;
    }
    p {
        font-size: 14px;
    }
}

.home_price_guarantee_container {
    position: relative;
    width: 100%;
    height: 220px;
    margin-top: 30px;
}

.home_price_guarantee_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/c_crop,h_600,q_auto:eco,w_1280/v1650685246/Home_page/IMG_4411_gyg0el.webp');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.home_price_guarantee_bg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.home_price_guarantee_text_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.home_price_guarantee_text_left {
    width: 40%;
    h3 {
        color: #fddc3a;
        font-size: 3em;
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
    }
    p {
        color: white;
        font-size: 14px;
        margin-top: 20px;
    }
}

.home_price_guarantee_text_right {
    width: 40%;
    display: flex;
    justify-content: center;
}

.home_price_guarantee_button {
    width: 300px;
    height: 40px;
    border: none;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
}

.home_youtube_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home_youtube_video {
    width: 45%;
}

.home_items_title {
    margin-top: 50px;
    text-align: center;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
}

.home_product_category_bg {
    position: relative;
    width: 100%;
    background: #f4f5f9;
    padding: 30px 0 60px 0;
    top: -30px;
}
.home_item_box_title {
    text-align: center;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 30px;
}

.home_item_box_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.home_item_box_pic_bg {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 130%;
}

.home_item_box_pic_text_box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
}

.home_item_box_pic_header {
    font-family: proxima-nova, sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.home_item_box_pic_more {
    font-family: proxima-nova, sans-serif;
    text-decoration: underline;
    color: #edc400;
    cursor: pointer;
}

@media (max-width: 768px) {
    .carousel-box {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        z-index: 1;
    }
    .home_big_pic_full {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-position: right;
    }
    .home_big_pic_full::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.6),
            rgba(255, 255, 255, 0.1)
        );
    }
    .slug-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slug-box {
        width: 100%;
    }
    .slug-text {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // padding-top: 20px;
        h2 {
            position: relative;
            top: -20px;
            font-size: 2em;
            text-align: center;
        }
        .home_big_des {
            // display: none;
            width: 80%;
            height: 40px;
            font-size: 18px;
            text-align: center;
        }
        .home_big_button {
            position: absolute;
            bottom: 150px;
            width: 80%;
            height: 50px;
            text-align: center;
        }
    }
    .slug-count {
        position: absolute;
        bottom: 30px;
    }
    .home_big_pic_back {
        width: 50%;
        height: calc(100vh - 130px);
    }

    .home_page_ad {
        margin-bottom: 0px;
        height: 60px;
    }

    .home_page_ad_text {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }

    .home_big_pic_item {
        width: 100%;
        margin: 10px 0;
    }

    .home_small_bottom {
        width: 100%;
        height: 30%;
    }

    .home_small_title_box {
        width: 100%;
        // height: 100%;
        // background: rgba(0, 0, 0, 0.5);
        // border-radius: 10px;

        z-index: 2;
        // &:hover {
        //     box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        // }
        h2 {
            font-size: 1.5em;
            color: white;
        }
        p {
            color: white;
            margin-top: 1rem;
        }
    }

    .home_small_pic {
        width: 100%;
        height: 70%;
        padding-bottom: 70%;
    }
    .home_help_area {
        .home_help_box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .home_help_icon {
                width: 200px;
                height: 150px;
                border-radius: 30px;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .home_help_name_mobile {
                    display: block;
                    margin-top: 10px;
                    font-size: 20px;
                    text-align: center;
                    padding: 0 20px;
                }
            }
            .home_help_name {
                display: none;
            }
        }
    }

    .home_big_pic_box {
        display: flex;
        flex-direction: column;
        align-items: space-between;
    }

    .home_pic_point_box {
        display: flex;
        flex-direction: column;
        align-items: space-between;
    }

    .home_pic_point_item {
        width: 100%;
        margin: 10px 0;
    }

    .home_buying_container {
        width: 100%;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }

    .home_buying_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home_buying_logo {
        width: 80px;
        height: 80px;
        object-fit: contain;
    }

    .home_buying_right {
        width: 100%;
        padding: 30px 0;
        display: flex;
        justify-content: center;
    }

    .home_buying_right_box {
        width: 90%;
        margin-bottom: 20px;
        h3 {
            text-align: center;
        }
    }

    .home_design_center_container {
        position: relative;
        display: flex;
        width: 100%;
        min-height: 300px;
        justify-content: center;
        margin: 20px 0;
    }

    .home_design_center_text_area {
        width: 80%;
        h3 {
            // font-family: Oswald;
            font-size: 2em;
            color: white;
            text-align: center;
            padding: 30px 0 30px 0;
        }
        p {
            color: white;
            text-align: center;
            font-size: 16px;
            line-height: 25px;
        }
    }

    .home_design_center_button_box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;

        color: white;
        align-items: center;
        margin-top: 50px;
        .home_design_center_button_middle {
            padding: 10px 0;
        }
    }

    .home_design_center_button {
        height: 50px;
    }

    .home_items_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }

    .home_items_box {
        width: 70%;

        h5 {
            height: 60px;
        }
    }

    .home_price_guarantee_container {
        position: relative;
        width: 100%;
        height: 400px;
        margin-top: 30px;
    }

    .home_price_guarantee_text_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home_price_guarantee_text_left {
        width: 80%;
        h3 {
            color: #fddc3a;
            font-size: 2.2em;
        }
        p {
            color: white;
            font-size: 16px;
            margin-top: 20px;
        }
    }

    .home_price_guarantee_text_right {
        width: 80%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .home_price_guarantee_button {
        width: 100%;
        height: 50px;
        border: none;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
    }

    .home_design_center_text_logo_box {
        margin-top: 10px;
    }
    .home_youtube_video {
        width: 100%;
    }

    .home_item_box_grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    .home_item_box_pic_bg {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 60%;
    }

    .home_item_box_pic_text_box {
        height: 45%;
    }

    .home_item_box_pic_header {
        font-size: 25px;
        font-weight: 600;
    }

    .home_item_box_pic_more {
        font-size: 18px;
    }

    .home_youtube_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
