.shopping-list-box {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    .shopping-list-pic {
        width: 20%;
        img {
            width: 100%;
            height: 100px;
            object-fit: contain;
        }
    }
    .shopping-list-name-box {
        width: 40%;
        .shopping-list-name-name {
            height: 40px;
        }
        .shopping-list-name-size {
            font-size: 12px;
        }
        .shopping-list-name-remove {
            margin-top: 30px;
            font-size: 14px;
        }
    }
    .shopping-list-right {
        width: 30%;

        .shopping-list-right-up {
            display: flex;
            .shopping-list-qty {
                width: 50%;
            }
            .shopping-list-price {
                width: 50%;

                font-weight: 600;
                text-align: right;
            }
        }
    }
}

.shopping-list-option-title {
    font-size: 12px;
}

.shopping-list-option-detail {
    font-size: 12px;
    color: #9d9d9d;
}

.shopping-list-name-skuno {
    font-size: 12px;
    color: #9d9d9d;
    margin-bottom: 5px;
}

.shopping-lists {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shopping-list-qty-input {
    width: 50px;
    height: 30px;
    border: 1px solid #ccc;
    padding-left: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.shopping-list-right-bottom {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.shopping-list-button {
    button {
        width: 120px;
        height: 40px;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 15px;
        border: none;
        cursor: pointer;
        font-size: 14px;
        // transition: all 0.3s ease-in-out;
        // font-weight: 600;
        &:hover {
            box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        }
    }
}
.shopping-list-add-all {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0;
}
.shopping-list-button-cart {
    margin-left: 20px;
    width: 150px;
    height: 40px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    // transition: all 0.3s ease-in-out;
    // font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .shopping-list-box {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;
        border-top: 1px solid #ccc;
        padding-top: 30px;
        .shopping-list-pic {
            width: 20%;
            img {
                height: 80px;
                object-fit: contain;
            }
        }
        .shopping-list-name-box {
            width: 40%;
            .shopping-list-name-name {
                height: 40px;
            }
            .shopping-list-name-size {
                font-size: 12px;
            }
            .shopping-list-name-remove {
                margin-top: 30px;
                font-size: 14px;
            }
        }
        .shopping-list-right {
            width: 20%;

            .shopping-list-right-up {
                display: flex;
                flex-direction: column-reverse;
                .shopping-list-qty {
                    width: 100%;
                    margin-top: 10px;
                }
                .shopping-list-price {
                    padding-top: 0px;
                    font-weight: 600;
                    text-align: right;
                }
            }
        }
    }

    .shopping-list-button {
        button {
            width: 80px;
            height: 30px;
            background: #fddc3a 0% 0% no-repeat padding-box;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            font-size: 10px;
            transition: all 0.3s ease-in-out;
            // font-weight: 600;
            &:hover {
                box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
            }
        }
    }
}
