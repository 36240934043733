.page_end_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .page_end_button_box {
        margin-top: 10px;
        width: 450px;
        height: 60px;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        }
        .page_end_button_icon {
            padding: 10px;
        }
    }
}

.page_end_button_mobile {
    display: none;
}

@media (max-width: 768px) {
    .page_end_button {
        .page_end_button_box {
            width: 300px;
            height: 50px;
            padding: 50px 50px;
            border-radius: 40px;
        }
    }
    .page_end_button_mobile {
        display: block;
    }
}
