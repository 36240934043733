.section_title_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    .section_title_name {
        width: 60%;
        display: flex;
        justify-content: center;
        h3 {
            font-size: 24px;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .section_yellow_line {
        width: 120px;
        border: 2px solid #edc400;
    }
}
