.support_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    .support_section_one {
        width: 100%;

        .support_item {
            position: relative;
            width: 100%;
            height: 0px;
            padding-bottom: 70%;

            .support_item_box {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .support_item_title_box {
                    .support_item_full {
                        display: block;
                    }
                    width: 60%;
                    height: 30%;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 20px;
                    cursor: pointer;
                    z-index: 2;
                    &:hover {
                        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
                    }
                    h2 {
                        font-family: proxima-nova, sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2em;
                        color: $main_color;
                    }
                    p {
                        color: white;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
    .support_section_two {
        margin: 20px 0;
        .support_item_2 {
            position: relative;
            width: 100%;
            height: 0px;
            padding-bottom: 40%;

            .support_item_box_2 {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .support_item_title_box_2 {
                    width: 40%;
                    height: 30%;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 20px;
                    cursor: pointer;
                    z-index: 2;
                    &:hover {
                        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
                    }
                    h2 {
                        font-family: proxima-nova, sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2em;
                        color: $main_color;
                    }
                    p {
                        color: white;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

.support_bottom {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .support_bottom_item {
        width: 48%;
        height: 150px;
        background: #fff5c4 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .support_box {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        .support_section_one {
            .support_item {
                padding-bottom: 100%;
                .support_item_box {
                    .support_item_title_box {
                        width: 60%;
                        height: 50%;
                        .support_item_full {
                            display: none;
                        }
                        h2 {
                            font-size: 1.4em;
                        }
                        p {
                            margin-top: 8px;
                            font-size: 11px;
                        }
                    }
                }
            }
        }

        .support_section_two {
            margin: 20px 0;
            .support_item_2 {
                padding-bottom: 100%;

                .support_item_box_2 {
                    .support_item_title_box_2 {
                        width: 60%;
                        height: 50%;
                        background: #fddc3a;
                        opacity: 0.8;

                        h2 {
                            font-size: 2em;
                            color: #000;
                        }
                        p {
                            color: black;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .support_bottom {
        flex-direction: column;
        background: #fff5c4;
        padding: 30px 0;
        height: 240px;
        .support_bottom_item {
            width: 80%;
            height: 80px;

            background: #ffffff;
            border: none;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            .support_bottom_item_title {
                width: 53%;
                text-align: center;
            }
            .support_bottom_item_icon {
                color: $main_color;
            }
        }
    }
}
