.product_list_picture_point_container {
    display: flex;
    // align-items: stretch;

    .product_list_picture_point_desktop {
        width: 70%;
        display: block;
    }
    .product_list_picture_point_mobile {
        display: none;
    }
    .product_list_picture_point_style {
        position: relative;
        bottom: 2px;
        width: 30%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: scaleY(0.985);

        h3 {
            font-family: proxima-nova, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 1.8em;
            color: white;
        }
        p {
            color: white;
            margin-top: 10px;
            font-size: 14px;
        }
    }
}

.product_list_cabinet_container {
    .product_list_display_mobile {
        display: none;
    }
    width: 100%;
    .product_list_style_area {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        img {
            width: 100px;
            height: 50px;
            object-fit: contain;
        }

        .product_list_style_title {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h3 {
                font-size: 20px;
                font-weight: 600;
            }
            p {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
}

.product_list_types_container {
    margin-top: 20px;
    width: 100%;
    background: #f0f0f0;
    padding: 30px;
    .product_list_types_title {
        text-align: center;
        padding: 0 0 20px 0;
    }
    .product_list_types_box {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.product_list_types_item {
    width: 120px;
    height: 120px;
    border-radius: 15px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
    .product_list_types_item_pic {
        img {
            width: 60px;
            height: 60px;
            object-fit: contain;
        }
    }
    .product_list_types_item_name {
        margin-top: 5px;
        font-size: 12px;
    }
}

.product-type-box-selected {
    background: #edc400;
}
@media (max-width: 768px) {
    .product_list_picture_point_container {
        position: relative;
        display: flex;
        flex-direction: column;
        .product_list_picture_point_desktop {
            display: none;
        }
        .product_list_picture_point_mobile {
            width: 100%;
            display: block;
        }
        .product_list_picture_point_style {
            position: absolute;
            top: -2px;
            left: 0;
            width: 100%;
            height: 100px;
            z-index: 2;
        }
    }
    .product_list_types_container {
        .product_list_types_box {
            width: 320px;
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .product_list_types_item {
        width: 120px;
        height: 120px;

        margin: 20px;
        .product_list_types_item_pic {
            img {
                width: 60px;
                height: 60px;
                object-fit: contain;
            }
        }
        .product_list_types_item_name {
            margin-top: 5px;
            font-size: 12px;
        }
    }

    .product_list_cabinet_container {
        .product_list_display_mobile {
            display: block;
            margin-bottom: 10px;
        }
        width: 100%;

        .product_list_style_area {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            img {
                width: 100px;
                height: 50px;
                object-fit: contain;
            }

            .product_list_style_title {
                width: 50%;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    text-align: left;
                }
                p {
                    margin-top: 5px;
                    font-size: 12px;
                }
            }
        }
    }
}
