.payment-success-header {
    padding: 10px 0;
}

.payment-success-body {
    display: flex;
    padding: 30px 0;
}

.payment-success-left {
    width: 80px;
}

.payment-success-right {
    width: 100%;
}

.payment-success-body1 {
    font-size: 22px;
    font-weight: 600;
}

.payment-success-body2 {
    font-size: 14px;
    padding: 10px 0;
}

.payment-success-body3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
}

.payment-policy-container {
    width: 100%;
}
.payment-policy {
    width: 100%;
    height: 200px;
    overflow: scroll;
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    padding: 10px;
}

.payment-policy_info {
    padding: 10px 0;
}
.payment-policy_info_title {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
}

.payment-policy_info_detail {
    font-size: 12px;
}
