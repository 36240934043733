.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    z-index: 100;
    background: rgba(0, 0, 0, 0.1);
}
