.nav_header_container {
    display: flex;
    align-items: center;
    padding: 0 10px;

    .nav_header_category {
        display: flex;
        align-items: center;
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .nav_header_name {
        font-weight: 600;
    }
}

.nav_header_hr {
    display: none;
}
@media (max-width: 768px) {
    .nav_header_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: 20px 10px;
        .nav_header_category {
            display: flex;
            align-items: center;
            font-size: 12px;
        }
        .nav_header_name {
            margin-top: 10px;
            font-size: 14px;
        }
    }
    .nav_header_hr {
        display: block;
    }
}
