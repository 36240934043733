.signin-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 30px;
}
.signin-box {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.input-double {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input-item-half {
    width: 50%;
}

.input-tripler {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input-item-tripler {
    width: 33%;
}

.input-item-full {
    width: 100%;
}

.input-item-half-select {
    width: 50%;
    padding: 10px 10px;
    .form-control-nolabel {
    }
}
.signin-button-box {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.signin-button {
    width: 250px;
    height: 45px;
    background: #edc400 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.signin-info {
    width: 250px;
    text-align: center;
    font-size: 14px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .signin-box {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .input-double {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .input-tripler {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .input-item-half {
        width: 100%;
    }

    .input-item-tripler {
        width: 100%;
    }

    .input-item-full {
        width: 100%;
    }
    .input-item-half-select {
        width: 100%;
    }
}
