.footer_box {
    padding: 0 10px;
}
.footer_full {
    display: block;
}
.footer_container {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;

    .footer_logo {
        margin-bottom: 20px;
    }

    .footer_item {
        display: flex;
        flex-direction: column;
        // align-items: space-between;
        justify-content: space-between;
    }

    .footer_item_mobile_page {
        display: none;
    }
    .footer_item_full_page {
        display: block;
        display: flex;
        flex-direction: column;
        align-items: space-evenly;
        justify-content: space-between;
        .footer_font_yellow {
            color: $main_color;
        }
    }

    .footer_divider {
        margin: 50px 0;
    }
}

.footer_copyright {
    color: $color_grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .footer_copyright_font {
        font-size: 12px;
    }

    .footer_copyright_media_full {
        display: block;
        width: 220px;
        display: flex;
        justify-content: space-between;
        font-size: 30px;
        a {
            text-decoration: none;
            color: $color_grey;
        }
    }
}

.footer_media_icon {
    font-size: 40;
}

.footer_col1 {
    display: flex;

    height: 100%;
    flex-direction: column;
}

.footer_col1_middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_item_list,
.footer_col1 {
    font-size: 12px;
    a {
        color: black;
        text-decoration: none;
        &:hover {
            color: $main_color;
        }
    }
}

@media (max-width: 768px) {
    .footer_box {
        background: $yellow_background;
        padding: 30px 0;
    }

    .footer_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .footer_item_mobile_page {
            display: block;
            .footer_font_bold {
                font-size: 20px;
                font-weight: 700;
                margin-top: 10px;
                margin-bottom: 10px;
                a {
                    color: black;
                    text-decoration: none;
                }
            }
            .footer_copyright_media_mobile {
                color: $color_grey;
                display: flex;
                justify-content: space-around;
                width: 80%;
                margin-top: 30px;
            }
        }
        .footer_item_full_page {
            display: none;
        }
        .footer_item,
        .footer_col1,
        .footer_item_mobile_page {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            .footer_copyright_media_mobile {
                a {
                    text-decoration: none;
                    color: $color_grey;
                }
            }
        }
        .footer_item_mobile_page {
            margin-bottom: 10px;
        }

        .footer_item_list {
            margin: 0.75rem;
            font-size: 16px;
        }
    }
    .footer_copyright {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .footer_copyright_font {
            display: flex;
            justify-content: center;
        }
        .footer_copyright_media_full {
            display: none;
        }
    }
    .footer_full {
        display: none;
    }
}
