.boxing_container {
    position: relative;
    top: -10px;
    display: flex;
    width: 100%;
    min-height: 350px;
    justify-content: center;
    margin-bottom: 30px;
}

.boxing_bgpic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.boxing_text_box {
    // padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.boxing_text_area {
    position: absolute;
    bottom: 50px;
    width: 600px;
    h3 {
        // font-family: Oswald;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 2.2em;
        text-align: center;
        padding: 0px 0 20px 0;
    }
    p {
        text-align: center;
        font-size: 18px;
        line-height: 25px;
        word-wrap: wrap;
    }
}

.boxing_text_button {
    width: 300px;
    height: 40px;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
}

.boxing-promo-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px;
}
.boxing-card {
    position: relative;
    width: 28%;
    height: 0;
    padding-top: 28%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.boxing-color-box {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 50%;
}

.boxing-color-light {
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    height: 0;
    padding-top: 100%;
    background-color: #fff9d9;
}

.boxing-middle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    h1 {
        font-family: proxima-nova;
        font-style: italic;
        font-size: 80px;
        color: white;
    }
    h3 {
        position: relative;
        font-family: proxima-nova;
        font-style: italic;
        font-size: 30px;
        color: white;
        top: -20px;
    }
}

.boxing-color-square {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border: 3px solid black;
}

.boxing-title {
    position: absolute;
    width: 100%;
    top: 16%;
    text-align: center;
    font-size: 14px;
}
.boxing-policy {
    padding: 30px 0;
    margin-top: 30px;
    h4 {
        text-align: center;
        font-size: 20px;
    }
}
.boxing-text {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .boxing_text_area {
        width: 90%;
        h3 {
            // font-family: Oswald;

            font-size: 1.8em;
            text-align: center;
            padding: 0px 0 20px 0;
        }
        p {
            text-align: center;
            font-size: 16px;
            line-height: 25px;
            word-wrap: wrap;
        }
    }
    .boxing-promo-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
    .boxing-card {
        width: 80%;
        height: 0;
        padding-top: 80%;
    }
}
