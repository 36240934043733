.checkout-all-box {
}

.checkout-all-info-box {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}

.checkout-all-info-box-last {
    width: 100%;
    display: flex;
    padding: 20px 0;
}

.checkout-icon {
    width: 30px;
    display: flex;
    justify-content: center;
    margin-right: 30px;
}

.checkout-info {
    width: 100%;
}

.checkout-number {
    // background: #edc400 0% 0% no-repeat padding-box;
    background: #b4b2b2;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.checkout-title {
    font-size: 18px;
    font-weight: 600;
    padding: 0 0 20px 0;
    color: #b4b2b2;
}

.checkout-postcode-box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkout-postcode-title {
}

.checkout-postcode-button {
    border: none;
    background: #ffffff;
    cursor: pointer;
}

.checkout-stage-mobile {
    display: none;
}

.checkout-mobile-only {
    display: none;
}

.checkout-postcode-input {
    margin-left: 5px;
}

.checkout-continue-button {
    border: none;
    background: #fddc3a;
    width: 200px;
    height: 40px;
    border-radius: 36px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .checkout-icon-mobile {
        display: none;
    }
    .checkout-stage-mobile {
        display: flex;
        justify-content: space-around;
        padding: 30px 0;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .checkout-all-info-box {
        width: 100%;

        border-bottom: none;
        border-top: 1px solid #ccc;
        padding: 20px 0;
        display: flex;
        justify-content: center;
    }
    .checkout-all-info-box-last {
        width: 100%;
        border-bottom: none;
        border-top: 1px solid #ccc;
        display: flex;
        justify-content: center;
    }
    .checkout-icon {
        width: 30px;
        margin-right: 0px;
    }
    .checkout-number {
        color: black;
    }
    .checkout-dots {
        letter-spacing: 4px;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
    }
    .checkout-title {
        width: 100%;
        text-align: center;
    }

    .checkout-mobile-only {
        display: inline-block;
        margin-right: 5px;
    }

    .checkout-postcode-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .checkout-postcode-input {
        margin-left: 0px;
        padding: 20px 0;
        width: 100%;
        text-align: center;
    }

    .checkout-postcode-button {
        border: none;
        background: #fddc3a;
        width: 200px;
        height: 40px;
        border-radius: 36px;
        font-weight: 600;
        border: none;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        }
    }

    .checkout-continue-button-area {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.payment-button-box {
    text-align: center;
}
.payment-button {
    border: none;
    background: #fddc3a;
    width: 90%;
    height: 40px;
    border-radius: 36px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.payment-credit-title {
    font-size: 18px;
    font-weight: 600;
}

.payment-credit-info {
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 10px;
}

.payment-credit {
    display: flex;
    align-items: center;
}
.payment-method {
    padding: 20px 0;
}
