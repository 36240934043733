.login-button-box {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.login-button {
    width: 150px;
    height: 40px;
    background: #edc400 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.login-title {
    text-align: center;
    font-weight: 600;
    padding: 30px;
    font-size: 18px;
}

.login-remember {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #707070;
    font-size: 14px;
}

.login-sign-in-title {
    text-align: center;
    font-weight: 600;

    margin-top: 60px;
}

.resend-email-button {
    width: 200px;
    height: 40px;
    background: #edc400 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.login-forgetpassword {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    
    a {
        text-decoration: none;
        color: #000000;
    }
}

@media (max-width: 768px) {
    .login-box {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
}
