.warranty_style_title {
    position: relative;
    width: 100%;
    height: 0px;
    padding-bottom: 35%;

    .warranty_style_slogan {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .warranty_style_slogan_word {
            width: 70%;
            height: 100px;
            background: #000000 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 0.45;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            text-align: center;
            font-weight: 600;
        }
    }
}
.warranty_info_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .warranty_info_info {
        width: 80%;
        padding: 20px;
        .warranty_info_title {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 20px;
        }
        .warranty_info_detail {
            white-space: pre-line;
            line-height: 2;
        }
    }
}

@media (max-width: 768px) {
    .warranty_style_title {
        position: relative;
        width: 100%;
        height: 0px;
        padding-bottom: 45%;

        .warranty_style_slogan {
            .warranty_style_slogan_word {
                width: 80%;
                height: 80px;

                background: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 1) 50%,
                    rgba(0, 0, 0, 0) 100%
                );

                border-radius: 0px;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                padding: 30px;
            }
        }
    }
}
