.product_desc_container {
    .product_desc_box {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px 0;
        .product_desc_title {
            font-size: 18px;
            font-weight: 600;
        }
    }
    .product_desc_info {
        font-size: 12px;
        margin: 10px 0;
        white-space: pre-line;

        table {
            border: none;
            td {
                height: 20px;
                min-width: 100px;
                vertical-align: bottom;
            }
        }
    }
}

@media (max-width: 768px) {
    .product_desc_container {
        border-bottom: 1px solid #ccc;
        padding: 10px;
    }
}
