.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    max-height: 450px;
    padding: 20px;
    object-fit: contain;
}

.swiper-button-prev {
    color: transparent;
    background-image: url('https://res.cloudinary.com/homespace/image/upload/v1624934266/Icons/%E8%B7%AF%E5%BE%84_25_2x_qnyns6.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}

.swiper-button-next {
    color: transparent;
    background-image: url('https://res.cloudinary.com/homespace/image/upload/v1624637416/Icons/%E8%B7%AF%E5%BE%84_25_2x_ee0izr.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}

@media (max-width: 768px) {
    .swiper-slide img {
        display: block;
        width: 80%;
        max-height: 450px;
        padding: 10px 10px;
        object-fit: contain;
    }
}
