.order-info-box {
    width: 100%;
}

.order-info-detail-title {
    font-size: 18px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 10px;
}

.order-info-detail-box {
    display: flex;
    margin-top: 30px;
}

.order-info-detail-left {
    width: 50%;
}

.order-info-detail-right {
    width: 50%;
}
.order-left-box {
    display: flex;
    margin: 10px 0;
}

.left-title {
    width: 150px;
}

.order-left-box-flex {
    display: flex;
    margin: 10px 0;
}

.order-info-item-box {
    display: flex;
    padding: 20px 0;
    width: 100%;
}

.item-name-sku {
    color: #6a6a6a;
    font-size: 12px;
}

.order-items-title {
    font-size: 18px;
    font-weight: 600;
}

.order-items {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 30px 0;
}

.order-item-box {
    display: flex;
    margin: 10px 0;
    width: 100%;
}

.item-pic {
    width: 10%;
}

.item-name-area {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.item-name-left {
    width: 80%;
}

.item-name-right {
    width: 20%;
}

.item-price-area {
    width: 40%;
    text-align: right;
    font-weight: 600;
    display: flex;
    justify-content: space-evenly;
}

.payment-order-name-area {
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.payment-order-price-area {
    width: 20%;
    text-align: right;
}

.item-price-box {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.price-total {
    font-size: 16px;
    font-weight: 600;
}

.bill-address-margin {
    margin-top: 30px;
}

.order-track-button {
    width: 148px;
    height: 33px;
    background: #fff5c4 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.item-price-button {
    width: 120px;
    height: 30px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.left-title-full {
    width: 150px;
}

.item-price-button-mobile {
    display: none;
}

.order-action-box {
    display: flex;
    padding: 5px 0;
}
.order-action-item-date {
    width: 200px;
}

@media (max-width: 768px) {
    .order-info-detail-title {
        font-size: 18px;
        font-weight: 600;
        padding-top: 20px;
        padding-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
    }
    .order-info-detail-box {
        display: flex;
        flex-direction: column;
    }

    .order-info-detail-left {
        width: 100%;
    }
    .left-title {
        width: 140px;
    }
    .order-info-detail-right {
        width: 100%;
    }

    .left-title-full {
        display: none;
    }

    .order-right-button {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .order-track-button {
        width: 200px;
        height: 50px;
        border-radius: 39px;
        font-size: 18px;
        background: #fddc3a;
    }
    .item-name-area {
        width: 60%;
        display: flex;
        flex-direction: column;
    }
    .item-price-area {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .payment-order-name-area {
        width: 60%;
        display: flex;
        flex-direction: column;
    }

    .payment-order-price-area {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .payment-order-price-item {
        display: flex;
        flex-direction: column;
    }

    .item-pic {
        width: 30%;
    }
    .item-price-button-area {
        margin-top: 20px;
    }
    .item-price-button-mobile {
        display: block;
        width: 80px;
        height: 40px;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 25px;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        }
    }
    .item-price-button {
        display: none;
    }

    .item-name-left {
        width: 100%;
        padding: 0 10px 10px 10px;
    }

    .item-name-right {
        width: 100%;
        padding: 10px;
    }

    .order-action-box {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f5f5f5;
        padding: 10px 0;
    }
    .order-action-item-date {
        padding: 5px 0;
        font-weight: 600;
    }
}
