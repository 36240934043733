.side-drawer {
    height: auto;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 40px;
    left: 0;
    width: 50%;
    max-width: 300px;
    padding: 30px;
    z-index: 200;
    border-radius: 0 0.75rem 0.75rem 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;

    ul {
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    li {
        margin: 0.5rem 0;
    }

    .nav_font_blod {
        font-weight: 700;
        margin-top: 1rem;
    }
    a {
        color: #000;
        text-decoration: none;
        font-size: 1.2rem;

        &:hover,
        &:active {
            color: $main_color;
        }
    }
}

.side-drawer.open {
    transform: translateX(0);
}

@media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

.toggle-button-line {
    width: 30px;
    height: 3px;
    background: $main_color;
}
