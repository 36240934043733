.bpg-header {
    position: relative;
    display: flex;
    flex-direction: column;
    top: -30px;
    width: 100%;
    height: 300px;
    justify-content: center;
    background-image: url('https://res.cloudinary.com/home-space/image/upload/v1631538174/Pictures_Kitchen/SG-F-Kitchen_1/gray_a_2x_jixgod.png');
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
}
.bpg-header::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.bpg-header-title {
    color: white;
    z-index: 2;
    text-align: center;
    h1 {
        font-family: Oswald;
        font-size: 50px;
        padding-bottom: 20px;
    }
}

.bpg_items_container {
    display: flex;
    justify-content: space-evenly;
}

.bpg_items_box {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 60px;
        object-fit: contain;
        padding-bottom: 30px;
    }
    h4 {
        text-align: center;
        padding-bottom: 20px;
    }
    p {
        font-size: 14px;
        text-align: center;
    }
}

.bpg-button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 60px 0 50px 0;
}

.bpg-button {
    width: 300px;
    height: 40px;
    border: none;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .bpg-header {
        top: 0px;
    }
    .bpg-header-title {
        width: 90%;
        h1 {
            font-family: Oswald;
            font-size: 30px;
            padding-bottom: 20px;
        }
    }

    .bpg_items_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bpg_items_box {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
}
