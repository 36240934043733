.products_big_container {
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    align-self: stretch;
}
.products_big_pic {
    position: relative;
    width: 70%;
    height: 0px;
    padding-bottom: 30%;
}

.products_big_pic_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;

    .products_big_pic_title_box {
        width: 100%;
        height: 100%;
        // background: rgba(0, 0, 0, 0.6);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        z-index: 2;

        h2 {
            font-family: proxima-nova, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 3em;
            color: white;
        }
        p {
            font-style: italic;
            color: white;
            margin-top: 1rem;
        }
        img {
            margin-top: 20px;
        }
    }
}

.products_small_pic {
    position: relative;
    width: 100%;
    height: 0px;
    padding-bottom: 100%;
    .products_small_pic_box {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 100%;
        &:hover {
            background: none;
        }
        .products_small_pic_title_box {
            width: 60%;
            height: 20%;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 20px;
            cursor: pointer;
            z-index: 2;
            &:hover {
                box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
            }
            h2 {
                font-family: proxima-nova, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1.5em;
                color: $main_color;
            }
        }
    }
}

@media (max-width: 768px) {
    .products_big_container {
        display: flex;
        flex-direction: column;
    }
    .products_big_pic {
        position: relative;
        width: 100%;
        height: 0px;
        padding-bottom: 60%;
    }
    .products_big_pic_box {
        width: 100%;
        .products_big_pic_title_box {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            h2 {
                // font-family: 'Oswald';
                font-size: 31px;
            }
            p {
                font-size: 12px;
            }
        }
    }

    .products_small_pic {
        .products_small_pic_box {
            .products_small_pic_title_box {
                width: 125px;
                height: 56px;
                h2 {
                    font-size: 1.5em;
                }
            }
        }
    }
}
