.order-box {
    width: 100%;
}

.order-header {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}

.order-header-item {
    width: 25%;
    font-weight: 600;
}

.order-item {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #f0f0f0;
}

.order-item-info {
    width: 25%;
}

.order-item-mobile {
    display: none;
}
.order-item-button {
    width: 148px;
    height: 33px;
    background: #fff5c4 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.order-header-mobile {
    display: none;
}

.order-item-button-mobile {
    display: none;
}

@media (max-width: 768px) {
    .order-header {
        display: none;
    }
    .order-item {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #f0f0f0;
        border-bottom: none;
    }
    .order-item-info {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }
    .mobile-strong {
        font-size: 18px;
        font-weight: 600;
        padding: 20px 0;
    }
    .order-item-mobile {
        display: block;
    }
    .order-header-mobile {
        display: block;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding: 20px 0;
    }
    .order-item-button {
        display: none;
    }
    .order-item-button-mobile {
        display: block;
        width: 200px;
        height: 50px;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 25px;
        border: none;
        cursor: pointer;
        font-size: 18px;

        &:hover {
            box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
        }
    }
}
