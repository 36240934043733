.payment-receipt-container {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    // padding: 10px;
    overflow: hidden;
}

.payment-receipt-logo {
    text-align: center;
    margin-top: 20px;
}

.payment-receipt-paid-text {
    color: #a0a0a5;
    margin: 20px 0;
    font-size: 18px;
}

.payment-receipt-small-text {
    color: #a0a0a5;
    font-size: 14px;
}

.receipt-checkmark {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.hr-color {
    border-top: 1px solid white;
}
