.guest-checkout-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.guest-checkout-info {
    width: 50%;
}

.checkout-cart {
    width: 300px;
}

.checkout-cart-box {
    border: 1px solid #c3c1c0;
    border-radius: 4px;
    padding: 20px;
}

.checkout-cart-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.checkout-cart-edit {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.checkout-cart-item-box {
    display: flex;
}

.checkout-cart-item {
    width: 70px;
    padding: 20px 0;
}

.checkout-cart-item-pic {
    width: 60px;
    height: 60px;
    border: 1px solid #dfe1e2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 40px;
        object-fit: contain;
    }
}

.checkout-cart-item-more {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-cart-item-number {
    width: 30px;
    height: 30px;
    border: 1px solid #707070;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.checkout-total-box {
    border: 1px solid #c3c1c0;
    border-radius: 4px;
    padding: 20px;
}

.checkout-total-area {
    margin-top: 30px;
}

.checkout-total-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .checkout-total-span-left {
        font-size: 14px;
    }
    .checkout-total-span-right {
        font-size: 14px;
        text-align: right;
    }
}

.checkout-total-span-left-strong {
    font-weight: 600;
}

.checkout-information-button {
    width: 180px;
    height: 40px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.checkout-information-button-box {
    padding: 20px 0;
}

@media (max-width: 768px) {
    .guest-checkout-box {
        padding: 0 10px;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin-top: 20px;
    }
    .guest-checkout-info {
        width: 100%;
    }
    .checkout-cart {
        width: 100%;
        border: 1px solid #61696d;
        padding: 20px;
        border-radius: 10px;
    }
    .checkout-cart-box {
        border: none;

        padding: 0;
    }
    .checkout-cart-item-box {
        display: none;
    }

    .checkout-total-area {
        margin-top: 20px;
    }

    .checkout-total-box {
        border: none;
        padding: 0;
    }
    .checkout-total-span-left-strong {
        font-weight: 400;
    }
    .checkout-information-button-box {
        text-align: center;
    }

    .checkout-information-button {
        background: #fddc3a;
    }
}
