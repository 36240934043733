.product_list_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .product_list_pic {
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 250px;
            object-fit: contain;
            cursor: pointer;
        }
    }
    .product_list_reversed {
        .product_list_word_area {
            width: 100%;
            height: 50px;
            .product_list_name {
                font-weight: 600;
                padding: 5px 0;
            }
            .product_list_measure {
                font-size: 14px;
                color: #61696d;
            }
        }

        .product_list_price {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }

    .product_list_bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .product_list_colors {
            position: relative;
            width: 45%;
            display: flex;
            flex-direction: column;
            .product_list_colors_title {
                font-size: 10px;
            }

            .product_list_colors_list {
                width: 100%;
                display: flex;
                margin-top: 10px;
                .product_list_color {
                    width: 25px;
                    height: 25px;
                    border: 1px solid #ccc;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
        }

        .product_list_add_button {
            width: 45%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            button {
                width: 200px;
                height: 45px;
                background: #fddc3a 0% 0% no-repeat padding-box;
                border-radius: 11px;
                border: none;
                cursor: pointer;
                font-size: 16px;
                &:hover {
                    box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
                }
            }
        }
    }
}

.line_mobile {
    display: none;
}

.product_list_color_more {
    width: 25px;
    height: 25px;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
}

.product_list_color_select {
    position: absolute;
    left: 0;
    bottom: -55px;
    width: 180px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    color: #ccc;
}

.product_list_color_select::before {
    content: '';
    position: absolute;
    left: 20px;
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px;
    border-color: transparent transparent #ccc;
    position: absolute;
}

.product_list_color_select::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 22px;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent #fff;
}

@media (max-width: 768px) {
    .product_list_container {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 15px;
        .product_list_pic {
            padding: 10px;

            img {
                width: 100%;
                height: 120px;
                object-fit: contain;
            }
        }
        .product_list_reversed {
            display: flex;
            flex-direction: column-reverse;
            .product_list_word_area {
                width: 100%;
                height: 60px;
                .product_list_name {
                    font-size: 14px;
                    font-weight: 600;
                    padding: 5px 0;
                }
                .product_list_measure {
                    font-size: 9px;
                    color: #61696d;
                }
            }
        }

        .product_list_price {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
            margin-bottom: 10px;
            height: 30px;
            .product_list_price_from {
                display: flex;
                flex-direction: column;
                .product_list_price_from_from {
                    font-size: 12px;
                    color: #61696d;
                }
            }
        }

        .product_list_bottom {
            width: 100%;
            display: flex;
            flex-direction: column;

            .product_list_colors {
                width: 100%;
                display: flex;
                flex-direction: column;
                .product_list_colors_title {
                    font-size: 10px;
                }
                .product_list_colors_list {
                    .product_list_color {
                        margin-right: 5px;
                    }
                }
            }

            .product_list_add_button {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 20px;

                button {
                    width: 150px;
                    height: 40px;
                    font-size: 14px;
                }
            }
        }
    }
    .line_mobile {
        display: block;
        border-bottom: 1px solid #ccc;
    }
}
