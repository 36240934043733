.request-design-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.request-design-item {
    width: 30%;
    box-shadow: 0px 3px 6px #00000029;
    margin: 10px;

    &:hover {
        box-shadow: 0px 5px 10px #00000029;
        cursor: pointer;
    }
}

.request-design-buildtype-pic {
    width: 100%;
    object-fit: contain;
}

.request-design-item-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
}

.request-design-item-date {
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding-bottom: 20px;
}

@media (max-width: 768px) {
    .request-design-item {
        width: 100%;
    }
}
