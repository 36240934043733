.product-option-container {
    position: fixed;
    left: calc(50% - 250px);
    top: calc(50% - 350px);
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.product-option-box {
    width: 500px;
    min-height: 400px;
    max-height: 90%;
    overflow: scroll;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    z-index: 201;
    padding: 20px;
}

.product-option-close-icon {
    text-align: right;
}

.product-option-name {
    font-weight: 600;
    font-size: 18px;
}
.product-option-price {
    margin-top: 10px;
    margin-bottom: 30px;
}

.product-option-door-box {
}

.product-option-door-star {
    color: red;
}

.product-option-door-select {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
}

.product-option-door-select-error {
    border: 1px dashed red;
}

.product-option-door-item {
    width: 48%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product-option-door-selected {
    background: #edc400;
}

.product-option-door-icon {
    width: 80px;
    height: 80px;
}

.product-option-divider {
    padding: 20px 0;
}

.product-custom-option {
    padding: 10px;
    background: #f0f0f0;
    border-radius: 10px;
}

.product-custom-option-title {
    font-size: 20px;
    margin-bottom: 20px;
}

.product-custom-option-glass {
    padding: 10px 0;
}

.product-custom-option-depth {
    padding: 10px 0;
}

.product-option-button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.product-option-button-style {
    width: 80%;
    height: 50px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .product-option-container {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-option-box {
        width: 90%;
        max-height: 90%;
        overflow: scroll;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        z-index: 201;
        padding: 20px;
    }
}
