.picture-point-area {
    position: relative;
}

.picture-point {
    width: 24px;
    height: 24px;
    background: rgba(66, 65, 63, 0.6);
    position: absolute;
    border: 1px solid #85796d;
    border-radius: 50%;
    z-index: 1;
}

.picture-point::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%) translateX(-50%);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: #edc400;
    z-index: 1;
}

.picture-point-detail {
    position: absolute;
    width: 160px;
    height: auto;
    background: rgba(221, 221, 221, 0.9);
    z-index: 999;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;

    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.picture-point-detail-yellow {
    position: absolute;
    width: 160px;
    height: auto;
    background: rgba(242, 214, 75, 0.9);
    z-index: 999;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.picture-point-detail-left {
    left: -170px;
}

.picture-point-detail-right {
    left: 30px;
}

.picture-point-name {
    position: absolute;
    left: 10px;
    top: 10px;
}

.picture-point-name-font {
    font-size: 12px;
}

.picture-point-des-font {
    font-size: 10px;
    margin-top: 5px;
}

@media (max-width: 768px) {
    .picture-point-name-font {
        font-size: 10px;
    }

    .picture-point-des-font {
        font-size: 9px;
        margin-top: 5px;
    }
    .picture-point-detail,
    .picture-point-detail-yellow {
        width: 120px;
        padding: 5px;
    }
    .picture-point-detail-left {
        left: -130px;
    }
}
