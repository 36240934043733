.shipping-option-box {
    max-width: 500px;
    margin: 30px 0;
}

.shipping-option-top {
    width: 100%;
    height: 40px;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border-radius: 21px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

.shipping-option-info {
    width: 100%;
    border: 1px solid #c3c1c0;
    border-radius: 4px;
    padding: 20px;
}

.shipping-option-item {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    cursor: pointer;
}

.delivery-box {
    display: flex;
}

.delivery-radio {
    width: 80px;
}

.delivery-radio-input {
    width: 15px;
    height: 15px;
    color: #fddc3a;
    background-color: #fddc3a;
}

.delivery-radio-input:checked {
    background-color: #fddc3a;
}

.delivery-title {
    font-size: 18px;
    font-weight: 600;
}

.delivery-condition {
    margin-top: 20px;
}

.pickup-title {
    font-size: 18px;
    font-weight: 600;
}

.pickup-detail {
    margin: 20px 0;
}

.pickup-list-box {
    width: 50%;
    display: flex;
}

.pickup-list-radio {
    width: 50px;
}

.pickup-address-box {
    display: flex;
}

.mobile-display {
    display: none;
}

@media (max-width: 768px) {
    .pickup-address-box {
        display: flex;
        flex-direction: column;
    }

    .pickup-list-box {
        width: 100%;
        margin: 10px 0;
    }

    .pickup-address-city {
        display: flex;
    }
    .mobile-display {
        display: block;
    }
}
