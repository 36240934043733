.printcart-list-option-title {
    font-size: 12px;
}

.printcart-list-option-detail {
    font-size: 12px;
    color: #9d9d9d;
}

.printcart-list-name-skuno {
    font-size: 12px;
    color: #9d9d9d;
    margin-bottom: 5px;
}

.printcart-list-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    border-top: 1px solid #ccc;
    padding: 20px;
    .printcart-list-pic {
        width: 20%;
        img {
            height: 80px;
            object-fit: contain;
        }
    }
    .printcart-list-name-box {
        width: 40%;
        .printcart-list-name-name {
        }
        .printcart-list-name-size {
            font-size: 12px;
        }
        .printcart-list-name-remove {
            margin-top: 30px;
            font-size: 14px;
        }
    }
    .printcart-list-right {
        width: 20%;
        display: flex;
        flex-direction: column;
        .printcart-list-qty {
            width: 100%;
            text-align: right;
            padding: 20px 0;
        }
        .printcart-list-price {
            padding-top: 0px;
            font-weight: 600;
            text-align: right;
        }
    }
}

.printcart-list-promo-delete {
    text-align: right;
    color: grey;
    font-size: 14px;
}

.printcart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.printcart-lists {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.printcart-total-box {
    width: 100%;
    border-top: 1px solid #ccc;
    margin-right: 0px;

    .printcart-total {
        width: 100%;
        border: none;
        border-radius: 0;
        padding: 20px;

        .printcart-total-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .printcart-total-span-left {
                font-size: 14px;
            }
            .printcart-total-span-right {
                font-size: 14px;
                text-align: right;
            }
        }
        .printcart-total-button {
            text-align: center;
            margin-top: 30px;
        }
    }
}
.printcart-button-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}
.printcart-button {
    width: 200px;
    height: 30px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

.printcart-logo {
    width: 200px;
    object-fit: contain;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 20mm;
}
