.aboutus_header {
    position: relative;
    width: 100%;
    height: 0px;
    padding-bottom: 25%;
    .aboutus_slogan {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .aboutus_slogan_word {
            font-size: 50px;
            font-family: proxima-nova, sans-serif;
            font-style: normal;
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            z-index: 1;
        }
    }
    .aboutus_slogan::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
    }
}

.aboutus_office_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0;
    margin-top: 20px;
    .aboutus_office_title {
        width: 300px;
        .aboutus_office_title_word {
            font-size: 24px;
            font-weight: 600;
        }
        .aboutus_office_title_line {
            margin-top: 20px;
            width: 80px;
            border: 2px solid #edc400;
        }
    }
    .aboutus_info {
        width: 250px;
        .aboutus_info_word {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            .aboutus_info_word_button {
                width: 180px;
                height: 50px;
                background: #fddc3a 0% 0% no-repeat padding-box;
                border-radius: 10px;
                border: none;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
    .aboutus_office_map {
        width: 40%;
        display: flex;
        justify-content: center;
    }
}

.aboutus_team_box {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .aboutus_team_title {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
    }
    .aboutus_team_person_area {
        width: 80%;
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
    }
}

.aboutus_team_person {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .aboutus_team_person_avatar {
        img {
            width: 100%;
            height: 150px;
            object-fit: contain;
            border-radius: 50%;
        }
    }
    .aboutus_team_person_name {
        margin-top: 10px;
        font-weight: 600;
        font-size: 20px;
    }
    .aboutus_team_person_title {
        font-size: 10px;
    }
}

.aboutus_gettoknow {
    width: 100%;
    display: flex;
    justify-content: center;
}

.aboutus_gettoknow_content {
    width: 70%;
    padding: 50px 0;
    p {
        padding: 10px 0;
    }
}
@media (max-width: 768px) {
    .aboutus_header {
        padding-bottom: 50%;
        .aboutus_slogan {
            .aboutus_slogan_word {
                font-size: 25px;
            }
        }
        .aboutus_slogan::before {
            background: none;
        }
    }

    .aboutus_office_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 30px 0;
        .aboutus_office_title {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .aboutus_office_title_word {
            }
            .aboutus_office_title_line {
            }
        }
        .aboutus_info {
            margin-top: 30px;
            width: 250px;
            .aboutus_info_word {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                .aboutus_info_word_button {
                    margin-left: auto;
                    margin-right: auto;
                    width: 200px;
                    height: 50px;

                    border-radius: 30px;
                }
            }
        }
        .aboutus_office_map {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    .aboutus_team_box {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .aboutus_team_title {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }
        .aboutus_team_person_area {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }
    }
    .aboutus_team_person {
        margin-top: 20px;

        margin-bottom: 20px;
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .aboutus_team_person_avatar {
            img {
                width: 100%;
                height: 150px;
                object-fit: contain;
                border-radius: 50%;
            }
        }
        .aboutus_team_person_name {
            margin-top: 10px;
            font-weight: 600;
            font-size: 20px;
        }
        .aboutus_team_person_title {
            font-size: 10px;
        }
    }

    .aboutus_gettoknow_content {
        width: 90%;
    }
}
