.atc-popup-box {
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    background: white;
    max-height: 100vh;
    z-index: 200;
    overflow: scroll;
    animation-name: leftout;
    animation-duration: 1s;
}

.atc-popup-header {
    display: flex;
    height: 60px;
    margin-bottom: 20px;
}

.atc-popup-header-left {
    width: 50%;
    border: 2px solid #edc400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.atc-popup-header-right {
    width: 50%;
    background: #edc400 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    cursor: pointer;
}

.atc-popup-addItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.atc-popup-addItem-box {
    width: 80%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.atc-popup-addItem-box.success {
    background: #e3f8ea 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.atc-popup-addItem-box.error {
    background: #ffeded 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

@keyframes leftout {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.addToCarts-Total {
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 18px;
    font-weight: 700;
}

.addToCarts-promoCode {
    width: 80%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: grey;
}

.addToCarts-button {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    button {
        width: 70%;
        height: 50px;
        background: #fddc3a 0% 0% no-repeat padding-box;
        border-radius: 25px;
        border: none;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .atc-popup-box {
        width: 80%;
    }
}
